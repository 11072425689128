import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory, useParams } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatients } from "store/actions/patient";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import Select from "react-select";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { patientMiddleware, EXPORT, CREATE } from "utils/middleware";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";
import { years } from "variables/options";
import { packageNo } from "variables/options";
import { currentDate } from "utils/currentDate";
import { months } from "variables/options";
import PackageExportCustomPagination from "./package/PackageExportCustomPagination";
import { getTownship } from "store/actions/township";

const PatientFOList = () => {
  const router = useHistory();
  const { townshipId } = useParams();

  const [exportLoading, setExportLoading] = useState(false);

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [townshipDRTBCode, setTownshipDRTBCode] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [packageExportLoading, setPackageExportLoading] = useState(false);
  const [packageExportUILoading, setPackageExportUILoading] = useState(false);
  const [packageExportUIs, setPackageExportUIs] = useState([]);
  const [packageExportStartYear, setPackageExportStartYear] = useState(null);
  const [packageExportEndYear, setPackageExportEndYear] = useState(null);
  const [packageExportNo, setPackageExportNo] = useState(null);
  const [packageTitles, setPackageTitles] = useState([]);
  const [toggle, setToggle] = useState(true);

  const [allPackageExportLoading, setAllPackageExportLoading] = useState(false);
  const [packageExportTotal, setPackageExportTotal] = useState(0);
  const [packageExportCurrentPage, setPackageExportCurrentPage] = useState(0);

  const { patients, male, female, total } = useSelector(
    (state) => state.patient
  );
  const loading = useSelector((state) => state.patient.loading);
  const user = useSelector((state) => state.auth);
  const township = useSelector((state) => state.township.township);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownship(townshipId))
  },[townshipId, dispatch])

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    if (!("page" in query)) {
      query.page = 1;
    }
    query.current_township_id = townshipId
    dispatch(getPatients(query));
  }, [dispatch, router.location.search, townshipId]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.current_township_id = townshipId;

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.SP_code = code;
    } else {
      delete query["SP_code"];
    }
    if (townshipDRTBCode !== "") {
      query.unique_id = townshipDRTBCode;
    } else {
      delete query["unique_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.current_township_id = townshipId;
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        UniqueKey: data.id,
        No: index + 1,
        Township: data.township?.name,
        Year: data.year,
        SP_Code: data.SP_code,
        RR_Code: data.RR_code,
        DRTB_Code: data.DRTB_code,
        Township_DRTB_Code: data.unique_id,
        Name: data.name,
        Age: data.age,
        Sex: data.sex,
        Treatment_Start_Date: data.treatment_start_date,
        TO_Status: data.TO_status,
        TO_Year: data.TO_year,
        TO_date: data.TO_date,
        TO_RR_Code: data.TO_RR_code,
        TO_DRTB_code: data.TO_DRTB_code,
        TO_UniqueID: data.TO_unique_id,
        TO_Township: data.TO_township?.name,
        Outcome: data.outcome,
        Outcome_Date: data.outcome_date,
        Remark: data.remark,
      }));

      let fileName = "All-Patients";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handlePackageExport = async () => {
    setPackageExportLoading(true);
    if (packageExportStartYear && packageExportEndYear && packageExportNo) {
      if (packageExportStartYear.value <= packageExportEndYear.value) {
        try {
          const query = queryString.parse(router.location.search);
          query.current_township_id = townshipId;

          query.start_year = packageExportStartYear.value;
          query.end_year = packageExportEndYear.value;
          query.package_no = packageExportNo.value;

          let titles = months.map(
            (month) => packageExportStartYear.value + "-" + month.label
          );
          if (packageExportStartYear.value < packageExportEndYear.value) {
            titles = titles.concat(
              months.map(
                (month) => packageExportEndYear.value + "-" + month.label
              )
            );
          }

          const response = await call(
            "get",
            `packages-export?${new URLSearchParams(query).toString()}`
          );
          const result = response.data.map((data, index) => {
            const dynamicFields = titles.reduce(
              (acc, title) => ({
                ...acc,
                [title]: data[title],
              }),
              {}
            );

            return {
              No: index + 1,
              Township: data.township,
              TO_status: data.TO_status,
              TO_township: data.TO_township,
              Name: data.name,
              SP_code: data.SP_code,
              ...dynamicFields,
              Outcome: data.outcome,
              Outcome_date: data.outcome_date,
              Remark: data.remark,
            };
          });

          let fileName = `Packages-${packageExportNo.value}-${packageExportStartYear.value}-${packageExportEndYear.value}`;

          if (response.status === "success") {
            excelExport(result, fileName);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select year and package no!");
    }
    setPackageExportLoading(false);
  };

  const handlePackageExportUI = async () => {
    setPackageExportUILoading(true);
    if (packageExportStartYear && packageExportEndYear && packageExportNo) {
      if (packageExportStartYear.value <= packageExportEndYear.value) {
        try {
          const query = queryString.parse(router.location.search);
          query.current_township_id = townshipId;
          query.start_year = packageExportStartYear.value;
          query.end_year = packageExportEndYear.value;
          query.package_no = packageExportNo.value;

          // let titles = [];
          // for (const key in monthDictionary) {
          //   let title =
          //     packageExportStartYear.value + "-" + monthDictionary[key];
          //   titles.push(title);
          // }
          let titles = months.map(
            (month) => packageExportStartYear.value + "-" + month.label
          );

          if (packageExportStartYear.value < packageExportEndYear.value) {
            // for (const key in monthDictionary) {
            //   let title =
            //     packageExportEndYear.value + "-" + monthDictionary[key];
            //   titles.push(title);
            // }
            titles = titles.concat(
              months.map(
                (month) => packageExportEndYear.value + "-" + month.label
              )
            );
          }
          setPackageTitles(titles);

          const response = await call(
            "get",
            `packages-export?${new URLSearchParams(query).toString()}`
          );

          if (response.status === "success") {
            let result = response.data;

            setPackageExportTotal(result.length);
            setPackageExportUIs(result);
            setPackageExportCurrentPage(1);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select year and package no!");
    }
    setPackageExportUILoading(false);
    setToggle(false);
  };

  const startIndex = (packageExportCurrentPage - 1) * paginationLimit;
  const endIndex = startIndex + paginationLimit;
  const packageExportUIData = packageExportUIs.slice(startIndex, endIndex);

  const handleAllPackageExport = async () => {
    setAllPackageExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.current_township_id = townshipId;

      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      const response = await call(
        "get",
        `all-package-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        No: index + 1,
        Patient: data.patient.name,
        Age: data.patient.age,
        Sex: data.patient.sex,
        SP_Code: data.patient.SP_code,
        RR_Code: data.patient.RR_code,
        DRTB_code: data.patient.DRTB_code,
        TO_RR_Code: data.patient.TO_RR_code,
        TO_DRTB_code: data.patient.TO_DRTB_code,
        Year: data.patient.year,
        Township: data.township?.abbreviation,
        Package_1: data.package_1,
        Package_1_reimbursement: data.package_1_reimbursement,
        Package_1_reimbursement_month_year:
          data.package_1_reimbursement_month_year,
        Package_1_received: data.package_1_received,
        Package_1_received_month_year: data.package_1_received_month_year,

        Package_2: data.package_2,
        Package_2_reimbursement: data.package_2_reimbursement,
        Package_2_reimbursement_month_year:
          data.package_2_reimbursement_month_year,
        Package_2_received: data.package_2_received,
        Package_2_received_month_year: data.package_2_received_month_year,

        Package_3: data.package_3,
        Package_3_reimbursement: data.package_3_reimbursement,
        Package_3_reimbursement_month_year:
          data.package_3_reimbursement_month_year,
        Package_3_received: data.package_3_received,
        Package_3_received_month_year: data.package_3_received_month_year,

        Package_4: data.package_4,
        Package_4_reimbursement: data.package_4_reimbursement,
        Package_4_reimbursement_month_year:
          data.package_4_reimbursement_month_year,
        Package_4_received: data.package_4_received,
        Package_4_received_month_year: data.package_4_received_month_year,

        Package_5: data.package_5,
        Package_5_reimbursement: data.package_5_reimbursement,
        Package_5_reimbursement_month_year:
          data.package_5_reimbursement_month_year,
        Package_5_received: data.package_5_received,
        Package_5_received_month_year: data.package_5_received_month_year,

        Package_6: data.package_6,
        Package_6_reimbursement: data.package_6_reimbursement,
        Package_6_reimbursement_month_year:
          data.package_6_reimbursement_month_year,
        Package_6_received: data.package_6_received,
        Package_6_received_month_year: data.package_6_received_month_year,

        Package_7: data.package_7,
        Package_7_reimbursement: data.package_7_reimbursement,
        Package_7_reimbursement_month_year:
          data.package_7_reimbursement_month_year,
        Package_7_received: data.package_7_received,
        Package_7_received_month_year: data.package_7_received_month_year,

        Package_8: data.package_8,
        Package_8_reimbursement: data.package_8_reimbursement,
        Package_8_reimbursement_month_year:
          data.package_8_reimbursement_month_year,
        Package_8_received: data.package_8_received,
        Package_8_received_month_year: data.package_8_received_month_year,

        Outcome: data.patient.outcome,
        OutcomeDate: data.patient.outcome_date,

        Support_received_date: data.support_received_date,
        Remark: data.remark,
      }));

      let fileName = "All-Package";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setAllPackageExportLoading(false);
  };

  const handleRefresh = () => {
    router.replace(router.location.pathname);
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  const showPatientListButton = () => {
    if (toggle) {
      return true;
    }
    if (packageExportUIs.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Patients ({township.name})</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button size="sm" color="primary" onClick={handleRefresh}>
                  <i class="fas fa-redo"></i>
                </Button>
                <Button size="sm" color="primary" onClick={handleFilter}>
                  <i className="fa fa-search" />
                </Button>

                {patientMiddleware(user?.user?.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push(`/admin/create-patient`)}
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Patient Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search SP Code..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={townshipDRTBCode}
                  onChange={(event) => setTownshipDRTBCode(event.target.value)}
                  placeholder="Search Township DRTB Code"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={6}>
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleAllPackageExport}
                    disabled={allPackageExportLoading}
                    className="mt-5"
                  >
                    {allPackageExportLoading ? "Loading" : "All Package Export"}
                  </Button>
                )}
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Male = {male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Female = {female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                  className="mt-5"
                >
                  Total = {total}
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>
                <Select
                  options={packageNo}
                  value={packageExportNo}
                  onChange={(value) => setPackageExportNo(value)}
                  placeholder="Select package no"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportStartYear}
                  onChange={(value) => setPackageExportStartYear(value)}
                  placeholder="Start year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportEndYear}
                  onChange={(value) => setPackageExportEndYear(value)}
                  placeholder="End year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={5}>
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePackageExport}
                  disabled={packageExportLoading}
                  className="mt-2"
                >
                  {packageExportLoading ? "Loading" : "Package Export"}
                </Button>
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePackageExportUI}
                  disabled={packageExportUILoading}
                  className="mt-2"
                >
                  {packageExportUILoading ? "Loading" : "Package UI"}
                </Button>
                {showPatientListButton() && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setToggle((prev) => !prev)}
                    className="mt-2"
                  >
                    {!toggle ? "Show Patient List" : "Show Package UI"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          {toggle ? (
            <>
              <CustomTable
                header={
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">SP Code</th>
                    <th scope="col">Action</th>
                    <th scope="col">TSP</th>
                    <th scope="col">RR Code</th>
                    <th scope="col">DRTB Code</th>
                    <th scope="col">Township DRTB Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Age</th>
                    <th scope="col">Sex</th>
                  </tr>
                }
                body={patients.map((row, index) => (
                  <tr key={row.id}>
                    <td>{index + 1}</td>
                    <td>{row.SP_code}</td>
                    <td>
                      <Button
                        size="sm"
                        color="success"
                        onClick={() =>
                          router.push(`/admin/patient-detail/${row.id}`)
                        }
                      >
                        Detail
                      </Button>
                      <Button
                        size="sm"
                        color="warning"
                        onClick={() =>
                          router.push(`/admin/patient-packages/${row.id}`)
                        }
                      >
                        Packages
                      </Button>
                    </td>
                    <td>{row.township?.abbreviation}</td>
                    <td>{row.RR_code}</td>
                    <td>{row.DRTB_code}</td>
                    <td>{row.unique_id}</td>
                    <td>{row.name}</td>
                    <td>{row.age}</td>
                    <td>{row.sex}</td>
                  </tr>
                ))}
              />
              {total > paginationLimit && (
                <CustomPagination pageCount={total / paginationLimit} />
              )}
            </>
          ) : (
            <>
              <CustomTable
                header={
                  <tr>
                    <th scope="col">Township</th>
                    <th scope="col">TO Status</th>
                    <th scope="col">TO Township</th>
                    <th scope="col">Name</th>
                    <th scope="col">SP Code</th>
                    {packageTitles.map((title, index) => (
                      <th key={index}>{title}</th>
                    ))}
                    <th scope="col">Outcome</th>
                    <th scope="col">Outcome Date</th>
                    <th scope="col">Remark</th>
                  </tr>
                }
                body={packageExportUIData?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.township}</td>
                    <td>{row.TO_status}</td>
                    <td>{row.TO_township}</td>
                    <td>{row.name}</td>
                    <td>{row.SP_code}</td>
                    {packageTitles.map((title, index) => (
                      <td
                        key={index}
                        style={{
                          backgroundColor: row[title] === "Yes" ? "" : "#f00",
                          color: row[title] === "Yes" ? "#000" : "#fff",
                        }}
                      >
                        {row[title]}
                      </td>
                    ))}
                    <td>{row.outcome}</td>
                    <td>{row.outcome_date}</td>
                    <td>{row.remark}</td>
                  </tr>
                ))}
              />
              {packageExportTotal > paginationLimit && (
                <PackageExportCustomPagination
                  page={packageExportCurrentPage}
                  pageCount={packageExportTotal / paginationLimit}
                  setPackageExportCurrentPage={setPackageExportCurrentPage}
                />
              )}
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default PatientFOList;
