import React, { useState } from 'react'
import { Card, CardHeader, Container, Row, Col, Button } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import BackBtn from 'utils/backBtn'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { getAllPackages, deletePackage, recoverPackage } from 'store/actions/package'
import CustomPagination from 'components/Pagination/Pagination'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'

const TrashPackageList = () => {
    const [alert, setAlert] = useState(null)
    const { packageState } = useSelector((state) => state)
    const { packages, loading, total } = packageState

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllPackages())
    }, [dispatch])

    const handleDelete = (id) => {
        setAlert(
            <CustomAlert
                onConfirm={async () => {
                    await dispatch(deletePackage(id))
                    setAlert(null)
                }}
                onCancel={() => setAlert(null)}
            />,
        )
    }

    const handleRecover = async (id) => {
        await dispatch(recoverPackage(id))
    }

    if (loading) {
        return <FullScreenLoading />
    }

    return (
        <>
            {alert}
            <Container className="mt-3" fluid>
                <BackBtn />
                <Card>
                    <CardHeader className="border-0">
                        <Row>
                            <Col xs="6">
                                <h3 className="mb-0">Packages</h3>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CustomTable
                        header={
                            <tr>
                                <th scope="col">ID</th>
                                <th scope='col'>Patient Name</th>
                                <th scope="col">Township</th>
                                <th scope="col">Package 1</th>
                                <th scope="col">Package 2</th>
                                <th scope="col">Package 3</th>
                                <th scope="col">Package 4</th>
                                <th scope="col">Package 5</th>
                                <th scope="col">Package 6</th>
                                <th scope="col">Package 7</th>
                                <th scope="col">Package 8</th>
                                <th scope="col">Action</th>
                            </tr>
                        }
                        body={packages.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.patient?.name}</td>
                                <td>{row.township?.abbreviation}</td>
                                <td>{row.package_1}</td>
                                <td>{row.package_2}</td>
                                <td>{row.package_3}</td>
                                <td>{row.package_4}</td>
                                <td>{row.package_5}</td>
                                <td>{row.package_6}</td>
                                <td>{row.package_7}</td>
                                <td>{row.package_8}</td>
                                <td>
                                    <Button
                                        size="sm"
                                        color="success"
                                        onClick={() => handleRecover(row.id)}
                                    >
                                        Recover
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    />
                    {total > paginationLimit && (
                        <CustomPagination pageCount={total / paginationLimit} />
                    )}
                </Card>
            </Container>
        </>
    )
}

export default TrashPackageList
