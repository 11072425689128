import {
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const PackageExportCustomPagination = ({ page, pageCount, setPackageExportCurrentPage  }) => {
  const count = Math.ceil(pageCount)
  const pageChange = (i) => {
    setPackageExportCurrentPage(i)
  }
  return (
    <CardFooter className="py-4 d-flex justify-content-end">
      <Pagination
        className="justify-content-end"
        style={{ maxWidth: '400px', overflowX: 'scroll' }}
      >
        {page > 1 && (
          <PaginationItem>
            <PaginationLink
              onClick={() => pageChange(parseInt(page) - 1)}
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        )}
        {Array(count)
          .fill(1)
          .map((_, index) => (
            <PaginationItem
              key={index}
              className={
                index + 1 === parseInt(page || 1) ? 'active' : ''
              }
            >
              <PaginationLink onClick={() => pageChange(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        {page < pageCount && (
          <PaginationItem>
            <PaginationLink
              onClick={() => pageChange(parseInt(page) + 1)}
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </CardFooter>
  )
}
export default  PackageExportCustomPagination