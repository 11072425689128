import { createSlice } from '@reduxjs/toolkit'
import {
  createPackage,
  getPackages,
  getPackage,
  updatePackage,
  deletePackage,
  getAllPackages,
  temDeletePackage,
  recoverPackage,
} from 'store/actions/package'

const initialState = {
  loading: false,
  success: false,
  packages: [],
  package: {},
  total: 0,
}

const packageSlice = createSlice({
  name: 'package',
  initialState,
  extraReducers: (builder) => {
    // Get packages
    builder.addCase(getPackages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.loading = false
      state.packages = action.payload.data
      state.total = action.payload.total
    })
    builder.addCase(getPackages.rejected, (state) => {
      state.loading = false
    })

    // Get all packages
    builder.addCase(getAllPackages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllPackages.fulfilled, (state, action) => {
      state.loading = false
      state.packages = action.payload.data
      state.total = action.payload.total
    })
    builder.addCase(getAllPackages.rejected, (state) => {
      state.loading = false
    })

    // Create packages
    builder.addCase(createPackage.pending, (state) => {
      state.success = false
    })
    builder.addCase(createPackage.fulfilled, (state, action) => {
      state.success = true
      state.packages = [action.payload, ...state.packages]
      state.total = state.total + 1
    })
    builder.addCase(createPackage.rejected, (state) => {
      state.success = false
    })

    // Get package
    builder.addCase(getPackage.pending, (state) => {
      state.loading = true
      state.success = false
    })
    builder.addCase(getPackage.fulfilled, (state, action) => {
      state.loading = false
      state.package = action.payload
    })
    builder.addCase(getPackage.rejected, (state) => {
      state.loading = false
      state.success = false
    })

    // Update package
    builder.addCase(updatePackage.pending, (state) => {
      state.success = false
    })
    builder.addCase(updatePackage.fulfilled, (state, action) => {
      let index = state.packages.findIndex(
        (p) => p.id === action.payload.id,
      )
      state.packages[index] = action.payload
      state.success = true
      state.package = action.payload
    })
    builder.addCase(updatePackage.rejected, (state) => {
      state.success = false
    })

    // Delete package
    builder.addCase(deletePackage.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deletePackage.fulfilled, (state, action) => {
      state.loading = false
      state.packages = state.packages.filter(
        (p) => p.id !== action.payload,
      )
      state.total = state.total - 1
    })
    builder.addCase(deletePackage.rejected, (state) => {
      state.loading = false
    })

      // Temp Delete Package
      .addCase(temDeletePackage.pending, (state) => {
        state.loading = true
      })
      .addCase(temDeletePackage.fulfilled, (state, action) => {
        state.loading = false
        state.packages = state.packages.filter(
          (p) => p.id !== action.payload,
        )
        state.total = state.total - 1
      })
      .addCase(temDeletePackage.rejected, (state) => {
        state.loading = false
      })

      // Recover Package
      .addCase(recoverPackage.pending, (state) => {
        state.loading = true
      })
      .addCase(recoverPackage.fulfilled, (state, action) => {
        state.loading = false
        state.packages = state.packages.filter(
          (p) => p.id !== action.payload,
        )
        state.total = state.total - 1
      })
      .addCase(recoverPackage.rejected, (state) => {
        state.loading = false
      })
  },
})

export default packageSlice.reducer
