import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'

const Package7 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package7, setPackage7] = useState(null)
  const [package7Reimbursement, setPackage7Reimbursement] = useState(null)
  const [package7Received, setPackage7Received] = useState(null)
  const [
    package7ReimbursementMonthYears,
    setPackage7ReimbursementMonthYears,
  ] = useState([])
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_7,
      package_7_reimbursement,
      package_7_reimbursement_month_year,
      package_7_received,
      package_7_received_month_year,
    } = localPackage

    setValue(
      'package_7',
      package_7 ? { value: package_7, label: package_7 } : null,
    )
    setPackage7(package_7 ? { value: package_7, label: package_7 } : null)
    setValue(
      'package_7_reimbursement',
      package_7_reimbursement
        ? { value: package_7_reimbursement, label: package_7_reimbursement }
        : null,
    )
    setPackage7Reimbursement(
      package_7_reimbursement
        ? { value: package_7_reimbursement, label: package_7_reimbursement }
        : null,
    )
    setValue(
      'package_7_reimbursement_month_year',
      package_7_reimbursement_month_year,
    )
    setValue(
      'package_7_received',
      package_7_received
        ? { value: package_7_received, label: package_7_received }
        : null,
    )
    setPackage7Received(
      package_7_received
        ? { value: package_7_received, label: package_7_received }
        : null,
    )
    setValue('package_7_received_month_year', package_7_received_month_year)
    setPackage7ReimbursementMonthYears(
      package_7_reimbursement === 'Yes'
        ? package_7_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])
  const handleDelete = (deleteIndex) => {
    const filter = package7ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage7ReimbursementMonthYears(filter)
  }
  const onSubmit = (values) => {
    if (
      package7Reimbursement?.value === 'Yes' &&
      package7ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package7ReimbursementMonthYearsValue = package7ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_7: package7?.value,
      package_7_reimbursement:
        package7?.value === 'Yes' ? package7Reimbursement?.value : null,
      package_7_reimbursement_month_year:
        package7Reimbursement?.value === 'Yes'
          ? package7ReimbursementMonthYearsValue
          : null,
      package_7_received:
        package7?.value === 'Yes' ? package7Received?.value : null,
      package_7_received_month_year:
        package7Received?.value === 'Yes'
          ? values.package_7_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_7"
              label="Package 7"
              rules={{ required: 'Package 7 is required!' }}
              control={control}
              options={yesOrNo}
              value={package7}
              setData={setPackage7}
              placeholder="Package 7"
            />
          </Col>
        </Row>
        {package7?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_7_reimbursement"
                label="Package 7 Reimbursement"
                rules={{ required: 'Package 7 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package7Reimbursement}
                setData={setPackage7Reimbursement}
                placeholder="Package 7 Reimbursement"
              />
            </Col>
            {package7Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_7_reimbursement_month_year"
                    label="Package 7 Reimbursement Month"
                    register={{
                      ...register('package_7_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 7 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage7ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package7ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package7?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_7_received"
                label="Package 7 Received"
                rules={{ required: 'Package 7 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package7Received}
                setData={setPackage7Received}
                placeholder="Package 7 Received"
              />
            </Col>

            {package7Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_7_received_month_year"
                  label="Package 7 Received Month Year"
                  register={{
                    ...register('package_7_received_month_year', {
                      required: 'Package 7 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 7 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package7
