import Login from 'views/pages/auth/Login'
import Dashboard from 'views/pages/dashboard'
import AccountList from 'views/pages/dashboard/account/AccountList'
import DistrictList from 'views/pages/dashboard/basicData/district/DistrictList'
import RegionList from 'views/pages/dashboard/basicData/region/RegionList'
import TownshipList from 'views/pages/dashboard/basicData/township/TownshipList'
import ChangePassword from 'views/pages/auth/ChangePassword'
import PackageList from 'views/pages/dashboard/patient/package/PackageList'
import PackageCreate from 'views/pages/dashboard/patient/package/PackageCreate'
import PatientCreate from 'views/pages/dashboard/patient/PatientCreate'
import ProjectList from 'views/pages/dashboard/basicData/project/ProjectList'
import PatientEdit from 'views/pages/dashboard/patient/PatientEdit'
import PackageEdit from 'views/pages/dashboard/patient/package/PackageEdit'
import TrashPatientList from 'views/pages/dashboard/trash/TrashPatientList'
import PatientOutcomeEdit from 'views/pages/dashboard/patient/PatientOutcomeEdit'
import Report from 'views/pages/dashboard/report/Report'
import TrashPackageList from 'views/pages/dashboard/trash/TrashPackageList'
import CPIReportList from 'views/pages/dashboard/report/CPIReport'
import FilterTownshipList from 'views/pages/dashboard/patient/FilterTownshipList'
import PatientFOList from 'views/pages/dashboard/patient/PatientFOList'
import TOPatientFOList from 'views/pages/dashboard/patient/TOPatientFOList'
import PatientList from 'views/pages/dashboard/patient/PatientList'
import TOPatientList from 'views/pages/dashboard/patient/TOPatientList'

const routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-archive-2 text-green',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    icon: 'ni ni-archive-2 text-green',
    component: ChangePassword,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/account-list',
    name: 'Accounts',
    icon: 'ni ni-single-02 text-primary',
    component: AccountList,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Basic Data',
    icon: 'ni ni-chart-bar-32 text-primary',
    state: 'basicDataCollapse',
    views: [
      {
        path: '/region-list',
        name: 'State/Regions',
        miniName: 'RL',
        component: RegionList,
        layout: '/admin',
      },
      {
        path: '/district-list',
        name: 'Districts',
        miniName: 'DL',
        component: DistrictList,
        layout: '/admin',
      },
      {
        path: '/project-list',
        name: 'Projects',
        miniName: 'PL',
        component: ProjectList,
        layout: '/admin',
      },
      {
        path: '/township-list',
        name: 'Townships',
        miniName: 'TL',
        component: TownshipList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Patients',
    icon: 'ni ni-collection text-primary',
    state: 'patientCollapse',
    views: [
      {
        path: '/patient-list',
        name: 'All Patients',
        miniName: 'AP',
        component: PatientList,
        layout: '/admin',
      },
      {
        path: '/TO-patient-list',
        name: 'TO/Patients',
        miniName: 'TO/P',
        component: TOPatientList,
        layout: '/admin',
      },
      {
        path: '/patient-township-list',
        name: 'All Patients(FO)',
        miniName: 'AP(FO)',
        component: FilterTownshipList,
        layout: '/admin',
      },
      {
        path: '/TO-patient-township-list',
        name: 'TO/Patients(FO)',
        miniName: 'TO/P(FO)',
        component: FilterTownshipList,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/patient-FO-list/:townshipId',
    name: 'All Patients(FO)',
    component: PatientFOList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/TO-patient-FO-list/:townshipId',
    name: 'TO/Patients(FO)',
    component: TOPatientFOList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/create-patient',
    name: 'Create Patient',
    icon: 'ni ni-collection text-primary',
    component: PatientCreate,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/patient-detail/:id',
    name: 'Patient Detail',
    icon: 'ni ni-collection text-primary',
    component: PatientEdit,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/patient-outcome-edit/:id',
    name: 'Patient Outcome Edit',
    icon: 'ni ni-collection text-primary',
    component: PatientOutcomeEdit,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/patient-packages/:patientId',
    name: 'Patient Packages',
    icon: 'ni ni-collection text-primary',
    component: PackageList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/create-patient-packages/:patientId',
    name: 'Create Patient Packages',
    icon: 'ni ni-collection text-primary',
    component: PackageCreate,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/patient-packages-details/:patientId/:id',
    name: 'Patient Packages Details',
    icon: 'ni ni-collection text-primary',
    component: PackageEdit,
    layout: '/admin',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Trash',
    icon: 'ni ni-app text-primary',
    state: 'trashCollapse',
    views: [
      {
        path: '/patient-trash-list',
        name: 'Patients',
        miniName: 'AP',
        component: TrashPatientList,
        layout: '/admin',
      },
      {
        path: '/package-trash-list',
        name: 'Packages',
        miniName: 'AP',
        component: TrashPackageList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Report',
    icon: 'ni ni-chart-pie-35 text-primary',
    state: 'reportCollapse',
    views: [
      {
        path: '/report',
        name: 'SPS Report',
        miniName: 'SPS',
        component: Report,
        layout: '/admin',
      },
      {
        path: '/CPI-report',
        name: 'CPI Report',
        miniName: 'CPI',
        component: CPIReportList,
        layout: '/admin',
      },
    ],
  },
]

export default routes
