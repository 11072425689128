import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'

const Package2 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package2, setPackage2] = useState(null)
  const [package2Reimbursement, setPackage2Reimbursement] = useState(null)
  const [package2Received, setPackage2Received] = useState(null)

  const [
    package2ReimbursementMonthYears,
    setPackage2ReimbursementMonthYears,
  ] = useState([])
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_2,
      package_2_reimbursement,
      package_2_reimbursement_month_year,
      package_2_received,
      package_2_received_month_year,
    } = localPackage

    setValue(
      'package_2',
      package_2 ? { value: package_2, label: package_2 } : null,
    )
    setPackage2(package_2 ? { value: package_2, label: package_2 } : null)
    setValue(
      'package_2_reimbursement',
      package_2_reimbursement
        ? { value: package_2_reimbursement, label: package_2_reimbursement }
        : null,
    )
    setPackage2Reimbursement(
      package_2_reimbursement
        ? { value: package_2_reimbursement, label: package_2_reimbursement }
        : null,
    )
    setValue(
      'package_2_reimbursement_month_year',
      package_2_reimbursement_month_year,
    )
    setValue(
      'package_2_received',
      package_2_received
        ? { value: package_2_received, label: package_2_received }
        : null,
    )
    setPackage2Received(
      package_2_received
        ? { value: package_2_received, label: package_2_received }
        : null,
    )
    setValue('package_2_received_month_year', package_2_received_month_year)
    setPackage2ReimbursementMonthYears(
      package_2_reimbursement === 'Yes'
        ? package_2_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])

  const onSubmit = (values) => {
    if (
      package2Reimbursement?.value === 'Yes' &&
      package2ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package2ReimbursementMonthYearsValue = package2ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_2: package2?.value,
      package_2_reimbursement:
        package2?.value === 'Yes' ? package2Reimbursement?.value : null,
      package_2_reimbursement_month_year:
        package2Reimbursement?.value === 'Yes'
          ? package2ReimbursementMonthYearsValue
          : null,
      package_2_received:
        package2?.value === 'Yes' ? package2Received?.value : null,
      package_2_received_month_year:
        package2Received?.value === 'Yes'
          ? values.package_2_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }
  const handleDelete = (deleteIndex) => {
    const filter = package2ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage2ReimbursementMonthYears(filter)
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_2"
              label="Package 2"
              rules={{ required: 'Package 2 is required!' }}
              control={control}
              options={yesOrNo}
              value={package2}
              setData={setPackage2}
              placeholder="Package 2"
            />
          </Col>
        </Row>
        {package2?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_2_reimbursement"
                label="Package 2 Reimbursement"
                rules={{ required: 'Package 2 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package2Reimbursement}
                setData={setPackage2Reimbursement}
                placeholder="Package 2 Reimbursement"
              />
            </Col>
            {package2Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_2_reimbursement_month_year"
                    label="Package 2 Reimbursement Month"
                    register={{
                      ...register('package_2_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 2 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage2ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package2ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package2?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_2_received"
                label="Package 2 Received"
                rules={{ required: 'Package 2 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package2Received}
                setData={setPackage2Received}
                placeholder="Package 2 Received"
              />
            </Col>

            {package2Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_2_received_month_year"
                  label="Package 2 Received Month Year"
                  register={{
                    ...register('package_2_received_month_year', {
                      required: 'Package 2 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 2 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package2
