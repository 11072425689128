import CustomTable from "components/Table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import { getOtherTOTownships } from "store/actions/otherTOTownship";
import { useParams } from "react-router-dom";
import CustomAlert from "components/Alert/CustomAlert";
import { deleteOtherTOTownship } from "store/actions/otherTOTownship";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";

const OtherTOTownship = () => {
  const { id } = useParams();

  const [alert, setAlert] = useState(null);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.otherTOTownship.loading);
  const otherTOTownship = useSelector((state) => state.otherTOTownship);

  useEffect(() => {
    dispatch(getOtherTOTownships(id));
  }, [dispatch, id]);

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deleteOtherTOTownship(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Card>
        <CardBody>
          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  ID
                </th>
                <th className="fixed-cell left" scope="col">
                  TO Year
                </th>
                <th>TO Date</th>
                <th>TO RR Code</th>
                <th>TO DRTB Code</th>
                <th>TO Township DRTB Code</th>
                <th>TO township</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={otherTOTownship.otherTOTownships.map((ele, index) => (
              <tr key={ele.id}>
                <td>{index + 1}</td>
                <td>{ele.TO_year}</td>
                <td>{ele.TO_date}</td>
                <td>{ele.TO_RR_code}</td>
                <td>{ele.TO_DRTB_code}</td>
                <td>{ele.TO_unique_id}</td>
                <td>{ele.TO_township?.name}</td>
                <td>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => handleDelete(ele.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default OtherTOTownship;
