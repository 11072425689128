import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'

const Package1 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package1, setPackage1] = useState(null)
  const [package1Reimbursement, setPackage1Reimbursement] = useState(null)

  const [
    package1ReimbursementMonthYears,
    setPackage1ReimbursementMonthYears,
  ] = useState([])

  const [package1Received, setPackage1Received] = useState(null)

  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_1,
      package_1_reimbursement,
      package_1_reimbursement_month_year,
      package_1_received,
      package_1_received_month_year,
    } = localPackage

    setValue(
      'package_1',
      package_1 ? { value: package_1, label: package_1 } : null,
    )
    setPackage1(package_1 ? { value: package_1, label: package_1 } : null)
    setValue(
      'package_1_reimbursement',
      package_1_reimbursement
        ? { value: package_1_reimbursement, label: package_1_reimbursement }
        : null,
    )
    setPackage1Reimbursement(
      package_1_reimbursement
        ? { value: package_1_reimbursement, label: package_1_reimbursement }
        : null,
    )
    setValue(
      'package_1_reimbursement_month_year',
      package_1_reimbursement_month_year,
    )
    setValue(
      'package_1_received',
      package_1_received
        ? { value: package_1_received, label: package_1_received }
        : null,
    )
    setPackage1Received(
      package_1_received
        ? { value: package_1_received, label: package_1_received }
        : null,
    )
    setValue('package_1_received_month_year', package_1_received_month_year)
    setPackage1ReimbursementMonthYears(
      package_1_reimbursement === 'Yes'
        ? package_1_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])

  const onSubmit = (values) => {
    if (
      package1Reimbursement?.value === 'Yes' &&
      package1ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package1ReimbursementMonthYearsValue = package1ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_1: package1?.value,
      package_1_reimbursement:
        package1?.value === 'Yes' ? package1Reimbursement?.value : null,
      package_1_reimbursement_month_year:
        package1Reimbursement?.value === 'Yes'
          ? package1ReimbursementMonthYearsValue
          : null,
      package_1_received:
        package1?.value === 'Yes' ? package1Received?.value : null,
      package_1_received_month_year:
        package1Received?.value === 'Yes'
          ? values.package_1_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }

  const handleDelete = (deleteIndex) => {
    const filter = package1ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage1ReimbursementMonthYears(filter)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_1"
              label="Package 1"
              rules={{ required: 'Package 1 is required!' }}
              control={control}
              options={yesOrNo}
              value={package1}
              setData={setPackage1}
              placeholder="Package 1"
            />
          </Col>
        </Row>
        {package1?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_1_reimbursement"
                label="Package 1 Reimbursement"
                rules={{ required: 'Package 1 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package1Reimbursement}
                setData={setPackage1Reimbursement}
                placeholder="Package 1 Reimbursement"
              />
            </Col>
            {package1Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_1_reimbursement_month_year"
                    label="Package 1 Reimbursement Month"
                    register={{
                      ...register('package_1_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 1 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage1ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package1ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package1?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_1_received"
                label="Package 1 Received"
                rules={{ required: 'Package 1 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package1Received}
                setData={setPackage1Received}
                placeholder="Package 1 Received"
              />
            </Col>

            {package1Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_1_received_month_year"
                  label="Package 1 Received Month Year"
                  register={{
                    ...register('package_1_received_month_year', {
                      required: 'Package 1 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 1 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package1
