import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { sex, years } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTownships } from "store/actions/township";
import { createPatient } from "store/actions/patient";
import { useCallback } from "react";
import { getPatients } from "store/actions/patient";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";

const PatientCreate = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const townships = useSelector((state) => state.township.townships);
  const { total } = useSelector((state) => state.patient);

  useEffect(() => {
    dispatch(getTownships());
    dispatch(getPatients());
  }, [dispatch]);

  const success = useSelector((state) => state.patient.success);

  const townshipOptions = townships?.map((township) => {
    return { value: township.id, label: township.name };
  });

  const onSubmit = async (values) => {
    setLoading(true);

    await dispatch(
      createPatient({
        name: values.name,
        age: values.age,
        SP_code: values.SP_code,
        RR_code: values.RR_code,
        DRTB_code: values.DRTB_code,
        township_id: selectedTownship.value,
        sex: selectedSex.value,
        year: selectedYear.value,
        treatment_start_date: values.treatment_start_date,
      })
    );

    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedSex(null);
    setSelectedTownship(null);
    setSelectedYear(null);
    reset();
  }, [reset, setSelectedSex, setSelectedTownship, setSelectedYear]);

  useEffect(() => {
    if (success) {
      formReset();
    }

    return () => formReset();
  }, [success, formReset]);

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>Create Patient</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomInput
                  id="SP_code"
                  label={`SP Code (Last SP Code is ${total})`}
                  register={{
                    ...register("SP_code", {
                      required: "SP Code is required!",
                    }),
                  }}
                  placeholder="Enter SP Code"
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="township"
                  label="Township"
                  rules={{ required: "Township is required!" }}
                  control={control}
                  options={townshipOptions}
                  value={selectedTownship}
                  setData={setSelectedTownship}
                  placeholder="Township"
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="RR_code"
                  label="RR Code (pre-enrolled)"
                  register={{
                    ...register("RR_code", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter RR Code"
                  errors={errors}
                  isRequired={false}
                  onChange={() => {
                    setValue("DRTB_code", "");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomInput
                  id="DRTB_code"
                  label="DRTB Code"
                  register={{
                    ...register("DRTB_code", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter DRTB Code"
                  errors={errors}
                  isRequired={false}
                  onChange={() => {
                    setValue("RR_code", "");
                  }}
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="name"
                  label="Name"
                  register={{
                    ...register("name", {
                      required: "Name is required!",
                    }),
                  }}
                  placeholder="Enter Name"
                  errors={errors}
                />
              </Col>
              <Col>
                <CustomNumberInput
                  id="age"
                  label="Age"
                  register={{
                    ...register("age", {
                      required: "Age is required!",
                    }),
                  }}
                  placeholder="Enter age"
                  errors={errors}
                  max={120}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="sex"
                  label="Sex"
                  rules={{ required: "Sex is required!" }}
                  control={control}
                  options={sex}
                  value={selectedSex}
                  setData={setSelectedSex}
                  placeholder="Sex"
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="year"
                  label="Year"
                  rules={{ required: "Year is required!" }}
                  control={control}
                  options={years}
                  value={selectedYear}
                  setData={setSelectedYear}
                  placeholder="Year"
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="treatment_start_date"
                  label="Treatment Start Date"
                  register={{
                    ...register("treatment_start_date", false),
                  }}
                  placeholder="Select Treatment Start Date"
                  errors={errors}
                  isRequired={false}
                  max={currentDate()}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button color="primary" size="sm" disabled={loading} type="submit">
              {loading ? "Loading" : "Save"}
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};
export default PatientCreate;
