import { createSlice } from '@reduxjs/toolkit'
import { updatePatientOutcome } from 'store/actions/patient'
import {
  createPatient,
  getPatients,
  getPatient,
  updatePatient,
  deletePatient,
  recoverPatient,
  temDeletePatient,
} from 'store/actions/patient'

const initialState = {
  loading: false,
  success: false,
  patients: [],
  patient: {},
  male: 0,
  female: 0,
  total: 0,
}

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  extraReducers: (builder) => {
    builder
      // Get Patients
      .addCase(getPatients.pending, (state) => {
        state.loading = true
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.loading = false
        state.patients = action.payload.data
        state.male = action.payload.male
        state.female = action.payload.female
        state.total = action.payload.total
      })
      .addCase(getPatients.rejected, (state) => {
        state.loading = false
      })

      // Create Patients
      .addCase(createPatient.pending, (state) => {
        state.success = false
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.success = true
        state.patients = [action.payload, ...state.patients]
        state.total = state.total + 1
      })
      .addCase(createPatient.rejected, (state) => {
        state.success = false
      })

      // Get Patient
      .addCase(getPatient.pending, (state) => {
        state.loading = true
        state.success = false
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.loading = false
        state.patient = action.payload
      })
      .addCase(getPatient.rejected, (state) => {
        state.loading = false
        state.success = false
      })

      // Update Patient
      .addCase(updatePatient.pending, (state) => {
        state.success = false
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        let index = state.patients.findIndex(
          (Patient) => Patient.id === action.payload.id,
        )
        state.patients[index] = action.payload
        state.success = true
        state.patient = action.payload
      })
      .addCase(updatePatient.rejected, (state) => {
        state.success = false
      })

      // Update Patient Outcome
      .addCase(updatePatientOutcome.pending, (state) => {
        state.success = false
      })
      .addCase(updatePatientOutcome.fulfilled, (state, action) => {
        let index = state.patients.findIndex(
          (Patient) => Patient.id === action.payload.id,
        )
        state.patients[index] = action.payload
        state.success = true
        state.patient = action.payload
      })
      .addCase(updatePatientOutcome.rejected, (state) => {
        state.success = false
      })

      // Delete Patient
      .addCase(deletePatient.pending, (state) => {
        state.loading = true
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.loading = false
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload,
        )
        state.total = state.total - 1
      })
      .addCase(deletePatient.rejected, (state) => {
        state.loading = false
      })

      // Temp Delete Patient
      .addCase(temDeletePatient.pending, (state) => {
        state.loading = true
      })
      .addCase(temDeletePatient.fulfilled, (state, action) => {
        state.loading = false
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload,
        )
        state.total = state.total - 1
      })
      .addCase(temDeletePatient.rejected, (state) => {
        state.loading = false
      })

      // Recover Patient
      .addCase(recoverPatient.pending, (state) => {
        state.loading = true
      })
      .addCase(recoverPatient.fulfilled, (state, action) => {
        state.loading = false
        state.patients = state.patients.filter(
          (patient) => patient.id !== action.payload,
        )
        state.total = state.total - 1
      })
      .addCase(recoverPatient.rejected, (state) => {
        state.loading = false
      })
  },
})

export default patientSlice.reducer
