export const roles = [
  {
    value: 'Senior Technical Adviser (TB)',
    label: 'Senior Technical Adviser (TB)',
  },
  { value: 'Deputy Programme Director', label: 'Deputy Programme Director' },
  { value: 'Programme Manager_MDR', label: 'Programme Manager_MDR' },
  { value: 'Programme Manager_AIS', label: 'Programme Manager_AIS' },
  {
    value: 'MEAL and Digital Tool Manager',
    label: 'MEAL and Digital Tool Manager',
  },
  {
    value: 'MEAL And Digital Tool Officer',
    label: 'MEAL And Digital Tool Officer',
  },
  { value: 'MEAL Associate', label: 'MEAL Associate' },
  { value: 'Project Officer', label: 'Project Officer' },
  { value: 'Field Officer', label: 'Field Officer' },
]

export const sex = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]

export const yesOrNo = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const years = [
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
]

export const reportMonths = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
  // { value: '1 Quarter', label: '1st Quarter' },
  // { value: '2 Quarter', label: '2nd Quarter' },
  // { value: '3 Quarter', label: '3rd Quarter' },
  // { value: '4 Quarter', label: '4th Quarter' },
  // { value: 'Annual', label: 'Annual' },
]

export const months = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
]

export const outcomes = [
  { value: 'Cured', label: 'Cured' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Died', label: 'Died' },
  { value: 'Lost to Follow up', label: 'Lost to Follow up' },
  { value: 'Failed', label: 'Failed' },
  { value: 'Move to XDR', label: 'Move to XDR' },
  { value: 'Not Evaluated', label: 'Not Evaluated' },
]

export const packageNo = [
  { value: 1, label: 'Package 1' },
  { value: 2, label: 'Package 2' },
  { value: 3, label: 'Package 3' },
  { value: 4, label: 'Package 4' },
  { value: 5, label: 'Package 5' },
  { value: 6, label: 'Package 6' },
  { value: 7, label: 'Package 7' },
  { value: 8, label: 'Package 8' },
]
