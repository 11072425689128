import React, { useState, useEffect } from 'react'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import DistrictCreate from './DistrictCreate'
import DistrictUpdate from './DistrictUpdate'
import BackBtn from 'utils/backBtn'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDistricts,
  getDistrict,
  deleteDistrict,
} from 'store/actions/district'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { basicDataMiddleware, CREATE, UPDATE, DELETE } from 'utils/middleware'
import CustomAlert from 'components/Alert/CustomAlert'

const DistrictList = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  const [alert, setAlert] = useState(null)

  const { district, auth } = useSelector((state) => state)
  const { loading, districts } = district
  const { user } = auth
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDistricts())
  }, [dispatch])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          dispatch(deleteDistrict(id))
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Districts</h3>
              </Col>

              {basicDataMiddleware(user.role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <th scope="col">Action</th>
                  )}
              </tr>
            }
            body={districts.map((district, index) => (
              <tr key={district.id}>
                <td>{index + 1}</td>
                <td>{district.name}</td>
                {basicDataMiddleware(user.role, UPDATE) &&
                  basicDataMiddleware(user.role, DELETE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await dispatch(getDistrict(district.id))
                          setIsUpdateOpen(true)
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(district.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <DistrictUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <DistrictCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

export default DistrictList
