import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'
const Package5 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package5, setPackage5] = useState(null)
  const [package5Reimbursement, setPackage5Reimbursement] = useState(null)
  const [package5Received, setPackage5Received] = useState(null)
  const [
    package5ReimbursementMonthYears,
    setPackage5ReimbursementMonthYears,
  ] = useState([])
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_5,
      package_5_reimbursement,
      package_5_reimbursement_month_year,
      package_5_received,
      package_5_received_month_year,
    } = localPackage

    setValue(
      'package_5',
      package_5 ? { value: package_5, label: package_5 } : null,
    )
    setPackage5(package_5 ? { value: package_5, label: package_5 } : null)
    setValue(
      'package_5_reimbursement',
      package_5_reimbursement
        ? { value: package_5_reimbursement, label: package_5_reimbursement }
        : null,
    )
    setPackage5Reimbursement(
      package_5_reimbursement
        ? { value: package_5_reimbursement, label: package_5_reimbursement }
        : null,
    )
    setValue(
      'package_5_reimbursement_month_year',
      package_5_reimbursement_month_year,
    )
    setValue(
      'package_5_received',
      package_5_received
        ? { value: package_5_received, label: package_5_received }
        : null,
    )
    setPackage5Received(
      package_5_received
        ? { value: package_5_received, label: package_5_received }
        : null,
    )
    setValue('package_5_received_month_year', package_5_received_month_year)
    setPackage5ReimbursementMonthYears(
      package_5_reimbursement === 'Yes'
        ? package_5_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])
  const handleDelete = (deleteIndex) => {
    const filter = package5ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage5ReimbursementMonthYears(filter)
  }
  const onSubmit = (values) => {
    if (
      package5Reimbursement?.value === 'Yes' &&
      package5ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package5ReimbursementMonthYearsValue = package5ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_5: package5?.value,
      package_5_reimbursement:
        package5?.value === 'Yes' ? package5Reimbursement?.value : null,
      package_5_reimbursement_month_year:
        package5Reimbursement?.value === 'Yes'
          ? package5ReimbursementMonthYearsValue
          : null,
      package_5_received:
        package5?.value === 'Yes' ? package5Received?.value : null,
      package_5_received_month_year:
        package5Received?.value === 'Yes'
          ? values.package_5_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_5"
              label="Package 5"
              rules={{ required: 'Package 5 is required!' }}
              control={control}
              options={yesOrNo}
              value={package5}
              setData={setPackage5}
              placeholder="Package 5"
            />
          </Col>
        </Row>
        {package5?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_5_reimbursement"
                label="Package 5 Reimbursement"
                rules={{ required: 'Package 5 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package5Reimbursement}
                setData={setPackage5Reimbursement}
                placeholder="Package 5 Reimbursement"
              />
            </Col>
            {package5Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_5_reimbursement_month_year"
                    label="Package 5 Reimbursement Month"
                    register={{
                      ...register('package_5_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 5 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage5ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package5ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package5?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_5_received"
                label="Package 5 Received"
                rules={{ required: 'Package 5 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package5Received}
                setData={setPackage5Received}
                placeholder="Package 5 Received"
              />
            </Col>

            {package5Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_5_received_month_year"
                  label="Package 5 Received Month Year"
                  register={{
                    ...register('package_5_received_month_year', {
                      required: 'Package 5 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 5 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package5
