import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Card,
  CardHeader,
  Nav,
  NavItem,
  Button,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import { getPatient } from "store/actions/patient";
import BackBtn from "utils/backBtn";
import OtherInfo from "./components/OtherInfo";
import Package1 from "./components/Package1";
import Package2 from "./components/Package2";
import Package3 from "./components/Package3";
import Package4 from "./components/Package4";
import Package5 from "./components/Package5";
import Package6 from "./components/Package6";
import Package7 from "./components/Package7";
import Package8 from "./components/Package8";
import { setOverrideLocalPackage } from 'store/reducers/localPackage'

const PackageCreate = () => {
  const { patientId } = useParams();
  const [activeTab, setActiveTab] = useState(1);

  const { localPackage, patient } = useSelector((state) => state);
  const { localPackage: localPackageState } = localPackage;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatient(patientId));
  }, [dispatch, patientId]);

  const handleSetAllPackageNo = () => {
    const data = {
      package_1: 'No',
      package_2: 'No',
      package_3: 'No',
      package_4: 'No',
      package_5: 'No',
      package_6: 'No',
      package_7: 'No',
      package_8: 'No',
    }

    dispatch(setOverrideLocalPackage(data))
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3>({patient.patient?.name}) Create Package</h3>
              </Col>
              <Col xs="6">
                <Button
                  color="success"
                  size="sm"
                  onClick={handleSetAllPackageNo}
                >
                  Set All Package No
                </Button>
              </Col>
            </Row>
            <Nav className="px-3 d-flex" style={{ gap: "10px" }}>
              <NavItem>
                <Button
                  color={activeTab === 1 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  Package 1
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 2 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(2);
                  }}
                  disabled={!localPackageState.package_1}
                >
                  Package 2
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 3 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(3);
                  }}
                  disabled={!localPackageState.package_2}
                >
                  Package 3
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 4 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(4);
                  }}
                  disabled={!localPackageState.package_3}
                >
                  Package 4
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 5 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(5);
                  }}
                  disabled={!localPackageState.package_4}
                >
                  Package 5
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 6 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(6);
                  }}
                  disabled={!localPackageState.package_5}
                >
                  Package 6
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 7 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(7);
                  }}
                  disabled={!localPackageState.package_6}
                >
                  Package 7
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 8 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(8);
                  }}
                  disabled={!localPackageState.package_7}
                >
                  Package 8
                </Button>
              </NavItem>
              <NavItem>
                <Button
                  color={activeTab === 9 ? "success" : "primary"}
                  size="sm"
                  onClick={() => {
                    setActiveTab(9);
                  }}
                  disabled={!localPackageState.package_8}
                >
                  Other Info
                </Button>
              </NavItem>
            </Nav>
          </CardHeader>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Package1 onNext={() => setActiveTab(2)} />
            </TabPane>
            <TabPane tabId={2}>
              <Package2 onNext={() => setActiveTab(3)} />
            </TabPane>
            <TabPane tabId={3}>
              <Package3 onNext={() => setActiveTab(4)} />
            </TabPane>
            <TabPane tabId={4}>
              <Package4 onNext={() => setActiveTab(5)} />
            </TabPane>
            <TabPane tabId={5}>
              <Package5 onNext={() => setActiveTab(6)} />
            </TabPane>
            <TabPane tabId={6}>
              <Package6 onNext={() => setActiveTab(7)} />
            </TabPane>
            <TabPane tabId={7}>
              <Package7 onNext={() => setActiveTab(8)} />
            </TabPane>
            <TabPane tabId={8}>
              <Package8 onNext={() => setActiveTab(9)} />
            </TabPane>
            <TabPane tabId={9}>
              <OtherInfo onNext={() => setActiveTab(1)} />
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </>
  );
};

export default PackageCreate;
