import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory, useParams } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getPackages, temDeletePackage } from "store/actions/package";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { getPatient } from "store/actions/patient";
import { setOverrideLocalPackage } from "store/reducers/localPackage";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";
import { patientMiddleware, EXPORT } from "utils/middleware";
import CustomAlert from "components/Alert/CustomAlert";
import { CREATE } from "utils/middleware";
import { DELETE } from "utils/middleware";

const PackageList = () => {
  const router = useHistory();
  const { patientId } = useParams();
  const [alert, setAlert] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const { packageState, patient, auth } = useSelector((state) => state);
  const { packages, loading, total } = packageState;
  const { user } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatient(patientId));
  }, [dispatch, patientId]);

  useEffect(() => {
    dispatch(getPackages(patientId));
  }, [dispatch, patientId]);

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const response = await call(
        "get",
        `patient-packages-export/${patientId}`
      );
      const result = response.data.map((data, index) => ({
        No: index + 1,
        Patient: data.patient.name,
        SP_Code: data.patient.SP_code,
        RR_Code: data.patient.RR_code,
        DRTB_code: data.patient.DRTB_code,
        TO_RR_Code: data.patient.TO_RR_code,
        TO_DRTB_code: data.patient.TO_DRTB_code,
        Township: data.township?.abbreviation,
        Package_1: data.package_1,
        Package_1_reimbursement: data.package_1_reimbursement,
        Package_1_reimbursement_month_year:
          data.package_1_reimbursement_month_year,
        Package_1_received: data.package_1_received,
        Package_1_received_month_year: data.package_1_received_month_year,

        Package_2: data.package_2,
        Package_2_reimbursement: data.package_2_reimbursement,
        Package_2_reimbursement_month_year:
          data.package_2_reimbursement_month_year,
        Package_2_received: data.package_2_received,
        Package_2_received_month_year: data.package_2_received_month_year,

        Package_3: data.package_3,
        Package_3_reimbursement: data.package_3_reimbursement,
        Package_3_reimbursement_month_year:
          data.package_3_reimbursement_month_year,
        Package_3_received: data.package_3_received,
        Package_3_received_month_year: data.package_3_received_month_year,

        Package_4: data.package_4,
        Package_4_reimbursement: data.package_4_reimbursement,
        Package_4_reimbursement_month_year:
          data.package_4_reimbursement_month_year,
        Package_4_received: data.package_4_received,
        Package_4_received_month_year: data.package_4_received_month_year,

        Package_5: data.package_5,
        Package_5_reimbursement: data.package_5_reimbursement,
        Package_5_reimbursement_month_year:
          data.package_5_reimbursement_month_year,
        Package_5_received: data.package_5_received,
        Package_5_received_month_year: data.package_5_received_month_year,

        Package_6: data.package_6,
        Package_6_reimbursement: data.package_6_reimbursement,
        Package_6_reimbursement_month_year:
          data.package_6_reimbursement_month_year,
        Package_6_received: data.package_6_received,
        Package_6_received_month_year: data.package_6_received_month_year,

        Package_7: data.package_7,
        Package_7_reimbursement: data.package_7_reimbursement,
        Package_7_reimbursement_month_year:
          data.package_7_reimbursement_month_year,
        Package_7_received: data.package_7_received,
        Package_7_received_month_year: data.package_7_received_month_year,

        Package_8: data.package_8,
        Package_8_reimbursement: data.package_8_reimbursement,
        Package_8_reimbursement_month_year:
          data.package_8_reimbursement_month_year,
        Package_8_received: data.package_8_received,
        Package_8_received_month_year: data.package_8_received_month_year,

        Support_received_date: data.support_received_date,
        Remark: data.remark,
      }));

      let fileName = `${patient.patient?.name}-Packages`;

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(temDeletePackage(id));
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">({patient.patient?.name}) Packages</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(user?.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      dispatch(setOverrideLocalPackage({}));
                      router.push(
                        `/admin/create-patient-packages/${patientId}`
                      );
                    }}
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Township</th>
                <th scope="col">Action</th>
                <th scope="col">Package 1</th>
                <th scope="col">Package 2</th>
                <th scope="col">Package 3</th>
                <th scope="col">Package 4</th>
                <th scope="col">Package 5</th>
                <th scope="col">Package 6</th>
                <th scope="col">Package 7</th>
                <th scope="col">Package 8</th>
              </tr>
            }
            body={packages.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.township?.abbreviation}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      dispatch(setOverrideLocalPackage({}));
                      router.push(
                        `/admin/patient-packages-details/${patientId}/${row.id}`
                      );
                    }}
                  >
                    Detail
                  </Button>

                  {patientMiddleware(user?.role, DELETE) && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  )}
                </td>
                <td>{row.package_1}</td>
                <td>{row.package_2}</td>
                <td>{row.package_3}</td>
                <td>{row.package_4}</td>
                <td>{row.package_5}</td>
                <td>{row.package_6}</td>
                <td>{row.package_7}</td>
                <td>{row.package_8}</td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default PackageList;
