import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getTownships } from "store/actions/township";
import BackBtn from "utils/backBtn";
import { useHistory } from "react-router";
import { patientMiddleware, EXPORT } from "utils/middleware";
import queryString from "query-string";
import { call } from "services/api";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { currentDate } from "utils/currentDate";
import Select from "react-select";
import { packageNo } from "variables/options";
import { years } from "variables/options";
import { months } from "variables/options";

const FilterTownshipList = () => {
  const router = useHistory();

  const [allPackageExportLoading, setAllPackageExportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [packageExportNo, setPackageExportNo] = useState(null);
  const [packageExportLoading, setPackageExportLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [packageExportStartYear, setPackageExportStartYear] = useState(null);
  const [packageExportEndYear, setPackageExportEndYear] = useState(null);

  const { townships } = useSelector((state) => state.township);
  const user = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  const handleNavigate = (townshipId) => {
    if (router.location.pathname === "/admin/patient-township-list") {
      router.push(`/admin/patient-FO-list/${townshipId}`);
    } else {
      router.push(`/admin/TO-patient-FO-list/${townshipId}`);
    }
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        UniqueKey: data.id,
        No: index + 1,
        Township: data.township?.name,
        Year: data.year,
        SP_Code: data.SP_code,
        RR_Code: data.RR_code,
        DRTB_Code: data.DRTB_code,
        Township_DRTB_Code: data.unique_id,
        Name: data.name,
        Age: data.age,
        Sex: data.sex,
        Treatment_Start_Date: data.treatment_start_date,
        TO_Status: data.TO_status,
        TO_Year: data.TO_year,
        TO_date: data.TO_date,
        TO_RR_Code: data.TO_RR_code,
        TO_DRTB_code: data.TO_DRTB_code,
        TO_UniqueID: data.TO_unique_id,
        TO_Township: data.TO_township?.name,
        Outcome: data.outcome,
        Outcome_Date: data.outcome_date,
        Remark: data.remark,
      }));

      let fileName = "All-Patients";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  const handleAllPackageExport = async () => {
    setAllPackageExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);

      if (startDate !== "") {
        query.start_date = startDate;
      } else {
        delete query["start_date"];
      }

      if (endDate !== "") {
        query.end_date = endDate;
      } else {
        delete query["end_date"];
      }

      const response = await call(
        "get",
        `all-package-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        No: index + 1,
        Patient: data.patient.name,
        Age: data.patient.age,
        Sex: data.patient.sex,
        SP_Code: data.patient.SP_code,
        RR_Code: data.patient.RR_code,
        DRTB_code: data.patient.DRTB_code,
        TO_RR_Code: data.patient.TO_RR_code,
        TO_DRTB_code: data.patient.TO_DRTB_code,
        Year: data.patient.year,
        Township: data.township?.abbreviation,
        Package_1: data.package_1,
        Package_1_reimbursement: data.package_1_reimbursement,
        Package_1_reimbursement_month_year:
          data.package_1_reimbursement_month_year,
        Package_1_received: data.package_1_received,
        Package_1_received_month_year: data.package_1_received_month_year,

        Package_2: data.package_2,
        Package_2_reimbursement: data.package_2_reimbursement,
        Package_2_reimbursement_month_year:
          data.package_2_reimbursement_month_year,
        Package_2_received: data.package_2_received,
        Package_2_received_month_year: data.package_2_received_month_year,

        Package_3: data.package_3,
        Package_3_reimbursement: data.package_3_reimbursement,
        Package_3_reimbursement_month_year:
          data.package_3_reimbursement_month_year,
        Package_3_received: data.package_3_received,
        Package_3_received_month_year: data.package_3_received_month_year,

        Package_4: data.package_4,
        Package_4_reimbursement: data.package_4_reimbursement,
        Package_4_reimbursement_month_year:
          data.package_4_reimbursement_month_year,
        Package_4_received: data.package_4_received,
        Package_4_received_month_year: data.package_4_received_month_year,

        Package_5: data.package_5,
        Package_5_reimbursement: data.package_5_reimbursement,
        Package_5_reimbursement_month_year:
          data.package_5_reimbursement_month_year,
        Package_5_received: data.package_5_received,
        Package_5_received_month_year: data.package_5_received_month_year,

        Package_6: data.package_6,
        Package_6_reimbursement: data.package_6_reimbursement,
        Package_6_reimbursement_month_year:
          data.package_6_reimbursement_month_year,
        Package_6_received: data.package_6_received,
        Package_6_received_month_year: data.package_6_received_month_year,

        Package_7: data.package_7,
        Package_7_reimbursement: data.package_7_reimbursement,
        Package_7_reimbursement_month_year:
          data.package_7_reimbursement_month_year,
        Package_7_received: data.package_7_received,
        Package_7_received_month_year: data.package_7_received_month_year,

        Package_8: data.package_8,
        Package_8_reimbursement: data.package_8_reimbursement,
        Package_8_reimbursement_month_year:
          data.package_8_reimbursement_month_year,
        Package_8_received: data.package_8_received,
        Package_8_received_month_year: data.package_8_received_month_year,

        Outcome: data.patient.outcome,
        OutcomeDate: data.patient.outcome_date,

        Support_received_date: data.support_received_date,
        Remark: data.remark,
      }));

      let fileName = "All-Package";

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setAllPackageExportLoading(false);
  };

  const handlePackageExport = async () => {
    setPackageExportLoading(true);
    if (packageExportStartYear && packageExportEndYear && packageExportNo) {
      if (packageExportStartYear.value <= packageExportEndYear.value) {
        try {
          const query = queryString.parse(router.location.search);

          query.start_year = packageExportStartYear.value;
          query.end_year = packageExportEndYear.value;
          query.package_no = packageExportNo.value;

          let titles = months.map(
            (month) => packageExportStartYear.value + "-" + month.label
          );
          if (packageExportStartYear.value < packageExportEndYear.value) {
            titles = titles.concat(
              months.map(
                (month) => packageExportEndYear.value + "-" + month.label
              )
            );
          }

          const response = await call(
            "get",
            `packages-export?${new URLSearchParams(query).toString()}`
          );
          const result = response.data.map((data, index) => {
            const dynamicFields = titles.reduce(
              (acc, title) => ({
                ...acc,
                [title]: data[title],
              }),
              {}
            );

            return {
              No: index + 1,
              Township: data.township,
              TO_status: data.TO_status,
              TO_township: data.TO_township,
              Name: data.name,
              SP_code: data.SP_code,
              ...dynamicFields,
              Outcome: data.outcome,
              Outcome_date: data.outcome_date,
              Remark: data.remark,
            };
          });

          let fileName = `Packages-${packageExportNo.value}-${packageExportStartYear.value}-${packageExportEndYear.value}`;

          if (response.status === "success") {
            excelExport(result, fileName);
          }
        } catch (_) {
          NotificationManager.error("Please try again!");
        }
      } else {
        NotificationManager.warning(
          "Please select end year greater than start date!"
        );
      }
    } else {
      NotificationManager.warning("Please select year and package no!");
    }
    setPackageExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3">
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">
                  Townships (
                  {router.location.pathname === "/admin/patient-township-list"
                    ? "Patients"
                    : "TO/Patients"}
                  )
                </h3>
              </Col>
              <Col xs="6" className="text-right">
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Patient Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={6}>
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleAllPackageExport}
                    disabled={allPackageExportLoading}
                    className="mt-5"
                  >
                    {allPackageExportLoading ? "Loading" : "All Package Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={3}>
                <Select
                  options={packageNo}
                  value={packageExportNo}
                  onChange={(value) => setPackageExportNo(value)}
                  placeholder="Select package no"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportStartYear}
                  onChange={(value) => setPackageExportStartYear(value)}
                  placeholder="Start year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Select
                  options={years}
                  value={packageExportEndYear}
                  onChange={(value) => setPackageExportEndYear(value)}
                  placeholder="End year"
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={5}>
                <Button
                  size="sm"
                  color="info"
                  onClick={handlePackageExport}
                  disabled={packageExportLoading}
                  className="mt-2"
                >
                  {packageExportLoading ? "Loading" : "Package Export"}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {townships.map((township) => (
                <Col className="mt-3" sm={3} key={township.id}>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => handleNavigate(township.id)}
                  >
                    {township.name}
                  </Button>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default FilterTownshipList;
