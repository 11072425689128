import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'

const Package3 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package3, setPackage3] = useState(null)
  const [package3Reimbursement, setPackage3Reimbursement] = useState(null)
  const [package3Received, setPackage3Received] = useState(null)
  const [
    package3ReimbursementMonthYears,
    setPackage3ReimbursementMonthYears,
  ] = useState([])
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_3,
      package_3_reimbursement,
      package_3_reimbursement_month_year,
      package_3_received,
      package_3_received_month_year,
    } = localPackage

    setValue(
      'package_3',
      package_3 ? { value: package_3, label: package_3 } : null,
    )
    setPackage3(package_3 ? { value: package_3, label: package_3 } : null)
    setValue(
      'package_3_reimbursement',
      package_3_reimbursement
        ? { value: package_3_reimbursement, label: package_3_reimbursement }
        : null,
    )
    setPackage3Reimbursement(
      package_3_reimbursement
        ? { value: package_3_reimbursement, label: package_3_reimbursement }
        : null,
    )
    setValue(
      'package_3_reimbursement_month_year',
      package_3_reimbursement_month_year,
    )
    setValue(
      'package_3_received',
      package_3_received
        ? { value: package_3_received, label: package_3_received }
        : null,
    )
    setPackage3Received(
      package_3_received
        ? { value: package_3_received, label: package_3_received }
        : null,
    )
    setValue('package_3_received_month_year', package_3_received_month_year)
    setPackage3ReimbursementMonthYears(
      package_3_reimbursement === 'Yes'
        ? package_3_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])
  const handleDelete = (deleteIndex) => {
    const filter = package3ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage3ReimbursementMonthYears(filter)
  }
  const onSubmit = (values) => {
    if (
      package3Reimbursement?.value === 'Yes' &&
      package3ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package3ReimbursementMonthYearsValue = package3ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_3: package3?.value,
      package_3_reimbursement:
        package3?.value === 'Yes' ? package3Reimbursement?.value : null,
      package_3_reimbursement_month_year:
        package3Reimbursement?.value === 'Yes'
          ? package3ReimbursementMonthYearsValue
          : null,
      package_3_received:
        package3?.value === 'Yes' ? package3Received?.value : null,
      package_3_received_month_year:
        package3Received?.value === 'Yes'
          ? values.package_3_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_3"
              label="Package 3"
              rules={{ required: 'Package 3 is required!' }}
              control={control}
              options={yesOrNo}
              value={package3}
              setData={setPackage3}
              placeholder="Package 3"
            />
          </Col>
        </Row>
        {package3?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_3_reimbursement"
                label="Package 3 Reimbursement"
                rules={{ required: 'Package 3 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package3Reimbursement}
                setData={setPackage3Reimbursement}
                placeholder="Package 3 Reimbursement"
              />
            </Col>
            {package3Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_3_reimbursement_month_year"
                    label="Package 3 Reimbursement Month"
                    register={{
                      ...register('package_3_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 3 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage3ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package3ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package3?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_3_received"
                label="Package 3 Received"
                rules={{ required: 'Package 3 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package3Received}
                setData={setPackage3Received}
                placeholder="Package 3 Received"
              />
            </Col>

            {package3Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_3_received_month_year"
                  label="Package 3 Received Month Year"
                  register={{
                    ...register('package_3_received_month_year', {
                      required: 'Package 3 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 3 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package3
