import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import accountReducer from "./reducers/account";
import regionReducer from "./reducers/region";
import districtReducer from "./reducers/district";
import projectReducer from "./reducers/project";
import townshipReducer from "./reducers/township";
import patientReducer from "./reducers/patient";
import packageReducer from "./reducers/package";
import localPackageReducer from "./reducers/localPackage";
import reportReducer from "./reducers/report";
import otherTOTownshipReducer from './reducers/otherTOTownship'

const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    region: regionReducer,
    district: districtReducer,
    project: projectReducer,
    township: townshipReducer,
    patient: patientReducer,
    packageState: packageReducer,
    localPackage: localPackageReducer,
    report: reportReducer,
    otherTOTownship: otherTOTownshipReducer,
  },
});

export default store;
