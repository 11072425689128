import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BackBtn from "utils/backBtn";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "./index.module.css";

const Dashboard = () => {
  return (
    <>
      <Container className="mt-3">
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Criteria</h3>
              </Col>
              <Col xs="6">
                <ReactHTMLTableToExcel
                  className="btn btn-success btn-sm"
                  table="table-to-xls"
                  filename={"Criteria"}
                  sheet="Criteria"
                  buttonText="Export"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <table id="table-to-xls" className={styles.styled_table}>
              <thead>
                <tr>
                  <th colSpan={2} scope="col">
                    Criteria and Necessary Information for Social Protection
                    Support for DR TB patients
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    Covered Townships- Mandalay (Mandalay Downtown 7 Townships,
                    Myingyan, Meikhtila, Taungtha, NyaungOo, Kyaukpadaung,
                    Moegoke, Singu, Pyinoolwin), Sagaing (Monywa, Shwebo, Katha,
                    Kalay, Tamu), Shan( Taunggyi, Kalaw, Lashio, Muse)
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td className={styles.primary}>
                    TA & specialist consultation at private facilities which
                    cannot be covered by public facilities
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- TA and Consultation charges support to reduce out
                    of pocket health expenditure and catastrophic cost
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients/ Newly
                    Diagnosed DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When public health facilities/ TBC
                    could not be done consultation and DR TB patients need to
                    seek specialist consultation at private health center for
                    his or her follow up care and treatment/ drug side effect
                    managment/ underlying comorbid diseases
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Support- 20000 MMK/ month/patient </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for consultations)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td className={styles.primary}>
                    Investigations which cannot be covered by NTP
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- Investigation cost support which cannot be covered
                    by NTP (NTP site closed/ not functioning) in order to reduce
                    out of pocket health expenditure
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients/ Newly
                    Diagnosed DR TB patients in AIS project townships except
                    Myingyan and NYO districts
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When public health facilities/ local
                    TBC could not be done consultation and DR TB patients need
                    to seek specialist consultation at private health center for
                    his or her follow up care and treatment/ drug side effect
                    managment/ underlying comorbid diseases
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Support- 50000 MMK/ month/patient (Maximum)</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for undertaking investigations)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td className={styles.primary}>
                    Medication support with fair price in the market (cannot be
                    covered by NTP)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- Medication charges support in order to reduce out
                    of pocket health expenditure
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients/ Newly
                    Diagnosed DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When public health facilities/ local
                    TBC could not be done consultation and DR TB patients need
                    to seek specialist consultation at private health center for
                    his or her follow up care and treatment/ drug side effect
                    managment/ underlying comorbid diseases
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Support- 20000 MMK/ month/patient (Maximum)</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for purchasing ancillary drugs)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td className={styles.primary}>Hospitalization support </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- Hospitalization charges support in order to reduce
                    out of pocket health expenditure
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients/ Newly
                    Diagnosed DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When DR TB patient admit at hospital
                    (either public or private) for his or her treatment
                    initiation/ his or her drug side effect treatment/ his or
                    her underlying comorbid diseases treatment
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Support- 100000MMK/ patient (Maximum 2 times throughout DR
                    TB treatment)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for hospitalization)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>5</td>
                  <td className={styles.primary}>
                    In kind award for "TB champion"
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- to practice IPC measures by both patient and his/
                    her family, to promote treatment adherence in order to
                    achieve better health status
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary- Ongoing Registered DR TB patients</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Condition- 1) Best IPC practices by assessing with infection
                    control checklist 2) Increase in BW by 5% of that of
                    Baseline within 3 months successively 3) at least 6 months
                    taken treatment continuously for OSTR patients and at least
                    1 year treatment taken continuously for OLTR/ LTR patients{" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Support- 20000MMK/ patient (as one time support throughout
                    the treatment)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached necessary patient's
                    information such as IC checklist, BW measurements)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>6</td>
                  <td className={styles.primary}>
                    Housing support for Homeless
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- to enhance properly practice IPC measures, to
                    access easily to health facility/ TBC to undertake care and
                    treatment regularly, to reduce catastrophic cost due to TB
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients and/ or Newly
                    enrolled DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Condition- Cannot effort to rent a space to access the
                    treatment regularly and/ or The existing space of the
                    patient cannot practice IPC measures effectively{" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Support- 30000MMK/ month/ patient (support as rental
                    charges/ support as renovation)
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached request letter by
                    patients with recommendation from field staffs/ local health
                    staffs)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>7</td>
                  <td className={styles.primary}>
                    Education support for the children of DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- to reduce out of pocket expenditure and
                    catastrophic cost due to TB and provide social protection
                    among DR TB patients and their families social protection{" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary- Ongoing Registered DR TB patients</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Condition- Patients who have child/ children who are
                    attending primary, middle and high school or undergraduate{" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Support- 15000MMK/ month/ patient</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached request letter by
                    patients with recommendation from field staffs/ local health
                    staffs)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>8</td>
                  <td className={styles.primary}>
                    Injection fees for LTR within Intensive Phase (6 month){" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- Injection charges support to reduce out of pocket
                    expenditure and catastrophic cost due to injection included
                    regimen among DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficary- Ongoing Registered DR TB patients and/or Newly
                    enrolled DR TB patients
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When local TBC/BHS could not given
                    injection services and DR TB patients need to seek private
                    sector or private healthcare provider for his/her injected
                    anti-TB treatment
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Support- 93000MMK/month/patient</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for taking injection)
                  </td>
                </tr>
                <tr className={styles.secondary}>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>9</td>
                  <td className={styles.primary}>
                    Outsourcing the baseline and follow-up investigation
                    services for diagnosed DRTB cases
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Purpose- Investigation cost support which cannot be covered
                    by NTP (NTP site closed/ not functioning) in order to reduce
                    out of pocket health expenditure
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Beneficiary- Ongoing Registered DR TB patients/ Newly
                    Diagnosed DR TB patients in Myingyan and NYO districts
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Eligibility condition- When public health facilities/ local
                    TBC could not be done consultation and DR TB patients need
                    to seek specialist consultation at private health center for
                    his or her follow up care and treatment/ drug side effect
                    managment/ underlying comorbid diseases
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Support- 150000 MMK for one time baseline investigation and
                    25000 MMK/month/patient
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    Type of support- cash (using organization existing form for
                    financial claim purpose with attached copied patient OPD
                    book/ any proven documents for undertaking investigations)
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Dashboard;
