export const CREATE = 'create'
export const READ = 'read'
export const UPDATE = 'update'
export const DELETE = 'delete'
export const EXPORT = 'export'

const DEVELOPER = 'Developer'
const SENIOR_TECHNICAL_ADVISER_TB = 'Senior Technical Adviser (TB)'
const DEPUTY_PROGRAMME_DIRECTOR = 'Deputy Programme Director'
const PROGRAMME_MANAGER_MDR = 'Programme Manager_MDR'
const PROGRAMME_MANAGER_AIS = 'Programme Manager_AIS'

const HIS_AND_DIGITAL_LISTERACY_MANAGER = 'MEAL and Digital Tool Manager'
const MEAL_AND_DIGITAL_TOOL_OFFICER = 'MEAL And Digital Tool Officer'
const MEAL_ASSOCIATE = 'MEAL Associate'
const PROJECT_OFFICER = 'Project Officer'
const FILED_OFFICER = 'Field Officer'

export const isSpecifiedTownship = (role) => role === FILED_OFFICER

export const accountMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true
  } else {
    return false
  }
}

export const basicDataMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true
  } else if (
    (role === SENIOR_TECHNICAL_ADVISER_TB ||
      role === DEPUTY_PROGRAMME_DIRECTOR ||
      role === PROGRAMME_MANAGER_MDR ||
      role === PROGRAMME_MANAGER_AIS) &&
    type === READ
  ) {
    return true
  } else {
    return false
  }
}
export const patientMiddleware = (role, type) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true
  } else if (
    (role === SENIOR_TECHNICAL_ADVISER_TB ||
      role === DEPUTY_PROGRAMME_DIRECTOR ||
      role === PROGRAMME_MANAGER_MDR ||
      role === PROGRAMME_MANAGER_AIS ||
      role === PROJECT_OFFICER ||
      role === FILED_OFFICER) &&
    (type === READ || type === EXPORT)
  ) {
    return true
  } else {
    return false
  }
}

export const trashMiddleware = (role) => {
  if (
    role === DEVELOPER ||
    role === HIS_AND_DIGITAL_LISTERACY_MANAGER ||
    role === MEAL_AND_DIGITAL_TOOL_OFFICER ||
    role === MEAL_ASSOCIATE
  ) {
    return true
  } else {
    return false
  }
}
