import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'
const Package4 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package4, setPackage4] = useState(null)
  const [package4Reimbursement, setPackage4Reimbursement] = useState(null)
  const [package4Received, setPackage4Received] = useState(null)
  const [package4ReimbursementMonthYears, setPackage4ReimbursementMonthYears] =
  useState([]);
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_4,
      package_4_reimbursement,
      package_4_reimbursement_month_year,
      package_4_received,
      package_4_received_month_year,
    } = localPackage

    setValue(
      'package_4',
      package_4 ? { value: package_4, label: package_4 } : null,
    )
    setPackage4(package_4 ? { value: package_4, label: package_4 } : null)
    setValue(
      'package_4_reimbursement',
      package_4_reimbursement
        ? { value: package_4_reimbursement, label: package_4_reimbursement }
        : null,
    )
    setPackage4Reimbursement(
      package_4_reimbursement
        ? { value: package_4_reimbursement, label: package_4_reimbursement }
        : null,
    )
    setValue(
      'package_4_reimbursement_month_year',
      package_4_reimbursement_month_year,
    )
    setValue(
      'package_4_received',
      package_4_received
        ? { value: package_4_received, label: package_4_received }
        : null,
    )
    setPackage4Received(
      package_4_received
        ? { value: package_4_received, label: package_4_received }
        : null,
    )
    setValue('package_4_received_month_year', package_4_received_month_year)
    setPackage4ReimbursementMonthYears(
      package_4_reimbursement === "Yes"
        ? package_4_reimbursement_month_year.split("|")
        : []
    );
  }, [setValue, localPackage])
  const handleDelete = (deleteIndex) => {
    const filter = package4ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex
    );

    setPackage4ReimbursementMonthYears(filter);
  };
  const onSubmit = (values) => {
    if (
      package4Reimbursement?.value === "Yes" &&
      package4ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error("Please choose reimbursement month years");
      return;
    }
    const package4ReimbursementMonthYearsValue =
      package4ReimbursementMonthYears.join("|");
    const data = {
      package_4: package4?.value,
      package_4_reimbursement:
        package4?.value === "Yes" ? package4Reimbursement?.value : null,
      package_4_reimbursement_month_year:
        package4Reimbursement?.value === "Yes"
          ? package4ReimbursementMonthYearsValue
          : null,
      package_4_received:
        package4?.value === "Yes" ? package4Received?.value : null,
      package_4_received_month_year:
        package4Received?.value === "Yes"
          ? values.package_4_received_month_year
          : null,
    };
    dispatch(setLocalPackage(data))
    onNext()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_4"
              label="Package 4"
              rules={{ required: 'Package 4 is required!' }}
              control={control}
              options={yesOrNo}
              value={package4}
              setData={setPackage4}
              placeholder="Package 4"
            />
          </Col>
        </Row>
        {package4?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_4_reimbursement"
                label="Package 4 Reimbursement"
                rules={{ required: 'Package 4 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package4Reimbursement}
                setData={setPackage4Reimbursement}
                placeholder="Package 4 Reimbursement"
              />
            </Col>
            {package4Reimbursement?.value === "Yes" && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_4_reimbursement_month_year"
                    label="Package 4 Reimbursement Month"
                    register={{
                      ...register("package_4_reimbursement_month_year", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 4 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage4ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package4ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package4?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_4_received"
                label="Package 4 Received"
                rules={{ required: 'Package 4 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package4Received}
                setData={setPackage4Received}
                placeholder="Package 4 Received"
              />
            </Col>

            {package4Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_4_received_month_year"
                  label="Package 4 Received Month Year"
                  register={{
                    ...register('package_4_received_month_year', {
                      required: 'Package 4 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 4 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package4
