import CustomSelect from 'components/Inputs/CustomSelect'
import React, { useState, useEffect } from 'react'
import { Row, Col, CardBody, Form, CardFooter, Button } from 'reactstrap'
import { yesOrNo } from 'variables/options'
import { useForm } from 'react-hook-form'
import CustomInput from 'components/Inputs/CustomInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLocalPackage } from 'store/reducers/localPackage'
import { NotificationManager } from 'react-notifications'
import { monthDictionary } from 'utils/tobaccoCondition'
const Package6 = ({ onNext }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [package6, setPackage6] = useState(null)
  const [package6Reimbursement, setPackage6Reimbursement] = useState(null)
  const [package6Received, setPackage6Received] = useState(null)
  const [
    package6ReimbursementMonthYears,
    setPackage6ReimbursementMonthYears,
  ] = useState([])
  const { localPackage } = useSelector((state) => state.localPackage)
  const dispatch = useDispatch()

  useEffect(() => {
    const {
      package_6,
      package_6_reimbursement,
      package_6_reimbursement_month_year,
      package_6_received,
      package_6_received_month_year,
    } = localPackage

    setValue(
      'package_6',
      package_6 ? { value: package_6, label: package_6 } : null,
    )
    setPackage6(package_6 ? { value: package_6, label: package_6 } : null)
    setValue(
      'package_6_reimbursement',
      package_6_reimbursement
        ? { value: package_6_reimbursement, label: package_6_reimbursement }
        : null,
    )
    setPackage6Reimbursement(
      package_6_reimbursement
        ? { value: package_6_reimbursement, label: package_6_reimbursement }
        : null,
    )
    setValue(
      'package_6_reimbursement_month_year',
      package_6_reimbursement_month_year,
    )
    setValue(
      'package_6_received',
      package_6_received
        ? { value: package_6_received, label: package_6_received }
        : null,
    )
    setPackage6Received(
      package_6_received
        ? { value: package_6_received, label: package_6_received }
        : null,
    )
    setValue('package_6_received_month_year', package_6_received_month_year)
    setPackage6ReimbursementMonthYears(
      package_6_reimbursement === 'Yes'
        ? package_6_reimbursement_month_year.split('|')
        : [],
    )
  }, [setValue, localPackage])
  const handleDelete = (deleteIndex) => {
    const filter = package6ReimbursementMonthYears.filter(
      (_, index) => index !== deleteIndex,
    )

    setPackage6ReimbursementMonthYears(filter)
  }
  const onSubmit = (values) => {
    if (
      package6Reimbursement?.value === 'Yes' &&
      package6ReimbursementMonthYears.length === 0
    ) {
      NotificationManager.error('Please choose reimbursement month years')
      return
    }
    const package6ReimbursementMonthYearsValue = package6ReimbursementMonthYears.join(
      '|',
    )
    const data = {
      package_6: package6?.value,
      package_6_reimbursement:
        package6?.value === 'Yes' ? package6Reimbursement?.value : null,
      package_6_reimbursement_month_year:
        package6Reimbursement?.value === 'Yes'
          ? package6ReimbursementMonthYearsValue
          : null,
      package_6_received:
        package6?.value === 'Yes' ? package6Received?.value : null,
      package_6_received_month_year:
        package6Received?.value === 'Yes'
          ? values.package_6_received_month_year
          : null,
    }
    dispatch(setLocalPackage(data))
    onNext()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="package_6"
              label="Package 6"
              rules={{ required: 'Package 6 is required!' }}
              control={control}
              options={yesOrNo}
              value={package6}
              setData={setPackage6}
              placeholder="Package 6"
            />
          </Col>
        </Row>
        {package6?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_6_reimbursement"
                label="Package 6 Reimbursement"
                rules={{ required: 'Package 6 Reimbursement is required!' }}
                control={control}
                options={yesOrNo}
                value={package6Reimbursement}
                setData={setPackage6Reimbursement}
                placeholder="Package 6 Reimbursement"
              />
            </Col>
            {package6Reimbursement?.value === 'Yes' && (
              <>
                <Col md={4}>
                  <CustomInput
                    id="package_6_reimbursement_month_year"
                    label="Package 6 Reimbursement Month"
                    register={{
                      ...register('package_6_reimbursement_month_year', {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Package 6 Reimbursement Month"
                    errors={errors}
                    type="month"
                    onChange={(event) =>
                      event.target.value &&
                      setPackage6ReimbursementMonthYears((prev) => [
                        ...prev,
                        event.target.value,
                      ])
                    }
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  {package6ReimbursementMonthYears.map((monthYear, index) => (
                    <Button
                      className="mt-3"
                      color="danger"
                      key={index}
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      {`${monthYear.split('-')[0]}-${
                        monthDictionary[monthYear.split('-')[1]]
                      }`}
                    </Button>
                  ))}
                </Col>
              </>
            )}
          </Row>
        )}
        {package6?.value === 'Yes' && (
          <Row>
            <Col md={4}>
              <CustomSelect
                id="package_6_received"
                label="Package 6 Received"
                rules={{ required: 'Package 6 Received is required!' }}
                control={control}
                options={yesOrNo}
                value={package6Received}
                setData={setPackage6Received}
                placeholder="Package 6 Received"
              />
            </Col>

            {package6Received?.value === 'Yes' && (
              <Col md={4}>
                <CustomInput
                  id="package_6_received_month_year"
                  label="Package 6 Received Month Year"
                  register={{
                    ...register('package_6_received_month_year', {
                      required: 'Package 6 Received Month is required!',
                    }),
                  }}
                  placeholder="Enter Package 6 Received Month"
                  errors={errors}
                  type="month"
                />
              </Col>
            )}
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        <Button color="primary" size="sm" type="submit">
          Next
        </Button>
      </CardFooter>
    </Form>
  )
}

export default Package6
