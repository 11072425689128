import CustomAlert from "components/Alert/CustomAlert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { temDeletePatient } from "store/actions/patient";
import { getPatient } from "store/actions/patient";
import { getTownships } from "store/actions/township";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { updatePatient } from "store/actions/patient";
import { useForm } from "react-hook-form";
import { sex, years, yesOrNo } from "variables/options";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import OtherTOTownship from "./OtherTOTownship";
import OtherTOTownshipCreate from "./OtherTOTownshipCreate";

const PatientEdit = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const router = useHistory();

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [TOStatus, setTOStatus] = useState(null);
  const [selectedTOYear, setSelectedTOYear] = useState(null);
  const [selectedTOTownship, setSelectedTOTownship] = useState(null);

  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patient.patient);
  const townships = useSelector((state) => state.township.townships);
  const patientLoading = useSelector((state) => state.patient.loading);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  useEffect(() => {
    const {
      name,
      age,
      SP_code,
      RR_code,
      DRTB_code,
      sex,
      year,
      treatment_start_date,
      township,
      TO_status,
      TO_year,
      TO_date,
      TO_RR_code,
      TO_DRTB_code,
      TO_township,
    } = patient;
    reset({
      name: name,
      age: age,
      SP_code: SP_code,
      RR_code: RR_code,
      DRTB_code: DRTB_code,
      sex: { value: sex, label: sex },
      year: { value: year, label: year },
      treatment_start_date: treatment_start_date,
      township: {
        value: township?.id,
        label: township?.name,
      },
      TO_status: {
        value: TO_status,
        label: TO_status,
      },
      TO_year: TO_year
        ? {
            value: TO_year,
            label: TO_year,
          }
        : null,
      TO_date: TO_date,
      TO_RR_code: TO_RR_code,
      TO_DRTB_code: TO_DRTB_code,
      TO_township_id: TO_township
        ? {
            value: TO_township?.id,
            label: TO_township?.name,
          }
        : null,
    });
    setSelectedSex({ value: sex, label: sex });
    setSelectedYear({ value: year, label: year });
    setSelectedTownship({
      value: township?.id,
      label: township?.name,
    });
    setTOStatus({ value: TO_status, label: TO_status });
    setSelectedTOYear(TO_year ? { value: TO_year, label: TO_year } : null);
    setSelectedTOTownship(
      TO_township
        ? {
            value: TO_township?.id,
            label: TO_township?.name,
          }
        : null
    );
  }, [reset, patient]);

  const townshipOptions = townships?.map((township) => {
    return { value: township.id, label: township.name };
  });

  const onSubmit = async (values) => {
    setLoading(true);
    await dispatch(
      updatePatient({
        id: patient.id,
        name: values.name,
        age: values.age,
        SP_code: values.SP_code,
        RR_code: values.RR_code,
        DRTB_code: values.DRTB_code,
        township_id: selectedTownship.value,
        sex: selectedSex.value,
        year: selectedYear.value,
        treatment_start_date: values.treatment_start_date,
        TO_status: TOStatus?.value,
        TO_year: TOStatus?.value === "No" ? null : selectedTOYear?.value,
        TO_date: TOStatus?.value === "No" ? null : values.TO_date,
        TO_RR_code: TOStatus?.value === "No" ? null : values.TO_RR_code,
        TO_DRTB_code: TOStatus?.value === "No" ? null : values.TO_DRTB_code,
        TO_township_id:
          TOStatus?.value === "No" ? null : selectedTOTownship?.value,
      })
    );

    setLoading(false);
  };

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(temDeletePatient(id));
          setAlert(null);
          router.push("/admin/patient-list");
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };
  useEffect(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  if (patientLoading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Patient Detail</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/patient-outcome-edit/${patient.id}`)
                    }
                  >
                    Edit Outcome
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="SP_code"
                    label="SP Code"
                    register={{
                      ...register("SP_code", {
                        required: "SP Code is required!",
                      }),
                    }}
                    placeholder="Enter SP Code"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="township"
                    label="Township"
                    rules={{ required: "Township is required!" }}
                    control={control}
                    options={townshipOptions}
                    value={selectedTownship}
                    setData={setSelectedTownship}
                    placeholder="Township"
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="RR_code"
                    label="RR Code (pre-enrolled)"
                    register={{
                      ...register("RR_code", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter RR Code"
                    errors={errors}
                    isRequired={false}
                    onChange={() => {
                      setValue("DRTB_code", "");
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="DRTB_code"
                    label="DRTB Code"
                    register={{
                      ...register("DRTB_code", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter DRTB Code"
                    errors={errors}
                    isRequired={false}
                    onChange={() => {
                      setValue("RR_code", "");
                    }}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="name"
                    label="Name"
                    register={{
                      ...register("name", {
                        required: "Name is required!",
                      }),
                    }}
                    placeholder="Enter Name"
                    errors={errors}
                  />
                </Col>
                <Col>
                  <CustomNumberInput
                    id="age"
                    label="Age"
                    register={{
                      ...register("age", {
                        required: "Age is required!",
                      }),
                    }}
                    placeholder="Enter age"
                    errors={errors}
                    max={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="sex"
                    label="Sex"
                    rules={{ required: "Sex is required!" }}
                    control={control}
                    options={sex}
                    value={selectedSex}
                    setData={setSelectedSex}
                    placeholder="Sex"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="year"
                    label="Year"
                    rules={{ required: "Year is required!" }}
                    control={control}
                    options={years}
                    value={selectedYear}
                    setData={setSelectedYear}
                    placeholder="Year"
                  />
                </Col>

                <Col md={4}>
                  <CustomSelect
                    id="TO_status"
                    label="TO Yes or No"
                    rules={{ required: "TO Yes or No is required!" }}
                    control={control}
                    options={yesOrNo}
                    value={TOStatus}
                    setData={setTOStatus}
                    placeholder="TO Yes or No"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start Date"
                    register={{
                      ...register("treatment_start_date", false),
                    }}
                    placeholder="Select Treatment Start Date"
                    errors={errors}
                    isRequired={false}
                    max={currentDate()}
                  />
                </Col>
              </Row>
              {TOStatus?.value === "Yes" && (
                <>
                  <Row>
                    <Col md={4}>
                      <CustomSelect
                        id="TO_year"
                        label="TO Year"
                        rules={{ required: "TO Year is required!" }}
                        control={control}
                        options={years}
                        value={selectedTOYear}
                        setData={setSelectedTOYear}
                        placeholder="TO Year"
                      />
                    </Col>
                    <Col md={4}>
                      <CustomDateInput
                        id="TO_date"
                        label="TO Date"
                        register={{
                          ...register("TO_date", {
                            required: "TO_date is required!",
                          }),
                        }}
                        placeholder="Select TO Date"
                        errors={errors}
                        isRequired={true}
                        max={currentDate()}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomSelect
                        id="TO_township_id"
                        label="TO Township"
                        rules={{ required: "TO Township is required!" }}
                        control={control}
                        options={townshipOptions}
                        value={selectedTOTownship}
                        setData={setSelectedTOTownship}
                        placeholder="TO Township"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <CustomInput
                        id="TO_RR_code"
                        label="TO RR Code"
                        register={{
                          ...register("TO_RR_code", {
                            required: false,
                          }),
                        }}
                        placeholder="Enter TO RR Code"
                        errors={errors}
                        isRequired={false}
                        onChange={() => {
                          setValue("TO_DRTB_code", "");
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomInput
                        id="TO_DRTB_code"
                        label="TO DRTB Code"
                        register={{
                          ...register("TO_DRTB_code", {
                            required: false,
                          }),
                        }}
                        placeholder="Enter TO DRTB Code"
                        errors={errors}
                        isRequired={false}
                        onChange={() => {
                          setValue("TO_RR_code", "");
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
            {patientMiddleware(user?.role, UPDATE) && (
              <CardFooter className="text-right">
                {patient?.TO_status === "Yes" && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    Add TO Township
                  </Button>
                )}
                <Button
                  color="danger"
                  size="sm"
                  disabled={loading}
                  onClick={handleDelete}
                >
                  {loading ? "Loading" : "Delete"}
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Update"}
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
        {patient?.TO_status === "Yes" && <OtherTOTownship />}
      </Container>

      <OtherTOTownshipCreate
        id={id}
        isOpen={isCreateOpen}
        toggle={setIsCreateOpen}
      />
    </>
  );
};

export default PatientEdit;
