export const CPIReportTitle = {
  1: "No. of clients supported for TA & specialist consultation (package 1)",
  2: "No. of clients supported for Investigations (package 2)",
  3: "No. of clients supported for Medication (package 3)",
  4: "No. of clients supported for Hospitalization (package 4)",
  5: "No. of clients in kind award supported (package 5)",
  6: "No. of clients supported for Housing (package 6)",
  7: "No. of clients supported for Education of the children of DR TB patients (package 7)",
  8: "No. of clients supported for Injection Supports (package 8)",
};
