import CustomDateInput from 'components/Inputs/CustomDateInput'
import { Button, CardBody, CardFooter, Col, Form, Row } from 'reactstrap'
import { currentDate } from 'utils/currentDate'
import CustomTextArea from 'components/Inputs/CustomTextArea'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import React, { useState, useEffect, useCallback } from 'react'
import { createPackage } from 'store/actions/package'
import { setOverrideLocalPackage } from 'store/reducers/localPackage'
import { useParams } from 'react-router'
import { updatePackage } from 'store/actions/package'
import CustomSelect from 'components/Inputs/CustomSelect'
import { patientMiddleware } from 'utils/middleware'
import { UPDATE } from 'utils/middleware'

const OtherInfo = ({ onNext }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm()
  const { patientId, id } = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [mounted, setMounted] = useState(false)

  const user = useSelector((state) => state.auth.user);
  const { localPackage, packageState, township } = useSelector((state) => state)
  const { success } = packageState
  const dispatch = useDispatch()

  const townships = township.townships?.map((township) => {
    return { value: township.id, label: township.name }
  })

  useEffect(() => {
    const {
      support_received_date,
      remark,
      township,
    } = localPackage.localPackage

    setValue('support_received_date', support_received_date)
    setValue('remark', remark)
    setValue(
      'township_id',
      township ? { value: township?.id, label: township?.name } : null,
    )
    setSelectedTownship(
      township ? { value: township?.id, label: township?.name } : null,
    )
  }, [setValue, localPackage.localPackage])

  const onSubmit = async (values) => {
    const data = {
      ...localPackage.localPackage,
      patient_id: patientId,
      ...values,
      township_id: selectedTownship?.value,
    }

    setLoading(true)
    if (id) {
      await dispatch(updatePackage(data))
    } else {
      await dispatch(createPackage(data))
    }
    setLoading(false)
  }

  const formReset = useCallback(() => {
    reset()
    dispatch(setOverrideLocalPackage({}))
    setMounted(true)
  }, [reset, dispatch])

  useEffect(() => {
    if (success && !id) {
      formReset()
    }
  }, [success, formReset, id])

  useEffect(() => {
    if (success && mounted && !id) {
      onNext()
      setMounted(false)
    }
  }, [success, mounted, onNext, id])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomDateInput
              id="support_received_date"
              label="Support Received Date"
              register={{
                ...register('support_received_date', {
                  required: 'Support Received Date is required!',
                }),
              }}
              placeholder="Select Support Received Date"
              errors={errors}
              isRequired={true}
              max={currentDate()}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register('remark'),
              }}
              placeholder="Enter Remark"
              errors={errors}
              isRequired={false}
            />
          </Col>
        </Row>
        {id && (
          <Row>
            <Col md={6}>
              <CustomSelect
                id="township_id"
                label="Township"
                rules={{ required: 'Township is required!' }}
                control={control}
                options={townships}
                value={selectedTownship}
                setData={setSelectedTownship}
                placeholder="Township"
              />
            </Col>
          </Row>
        )}
      </CardBody>
      {patientMiddleware(user?.role, UPDATE) && (
        <CardFooter className="text-right">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </CardFooter>
      )}
    </Form>
  )
}
export default OtherInfo
