import React, { useState } from 'react'
import { Card, CardHeader, Container, Row, Col, Button } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import { useHistory } from 'react-router'
import BackBtn from 'utils/backBtn'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getPatients } from 'store/actions/patient'
import queryString from 'query-string'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import Select from 'react-select'
import { isSpecifiedTownship } from 'utils/middleware'
import { getTownships } from 'store/actions/township'
import CustomPagination from 'components/Pagination/Pagination'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'
import { deletePatient } from 'store/actions/patient'
import { recoverPatient } from 'store/actions/patient'

const TrashPatientList = () => {
  const router = useHistory()

  const [alert, setAlert] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [name, setName] = useState('')
  const [code, setCode] = useState('')

  const { patients, total } = useSelector((state) => state.patient)
  const township = useSelector((state) => state.township)
  const loading = useSelector((state) => state.patient.loading)
  const user = useSelector((state) => state.auth)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTownships())
  }, [dispatch])

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    query.status = 0
    if (!('page' in query)) {
      query.page = 1
    }
    dispatch(getPatients(query))
  }, [dispatch, router.location.search])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (name !== '') {
      query.name = name
    } else {
      delete query['name']
    }

    if (code !== '') {
      query.SP_code = code
    } else {
      delete query['SP_code']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(deletePatient(id))
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  const handleRecover = async (id) => {
    await dispatch(recoverPatient(id))
  }

  if (loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Trash Patients</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search SP Code..."
                />
              </Col>
              <Col sm={3}>
                {!isSpecifiedTownship(user.role) && (
                  <Select
                    className="mt-3"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              </Col>
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">SP Code</th>
                <th scope="col">TSP</th>
                <th scope="col">RR Code</th>
                <th scope="col">DRTB Code</th>
                <th scope="col">Unique ID</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Sex</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={patients.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.SP_code}</td>
                <td>{row.township?.abbreviation}</td>
                <td>{row.RR_code}</td>
                <td>{row.DRTB_code}</td>
                <td>{row.unique_id}</td>
                <td>{row.name}</td>
                <td>{row.age}</td>
                <td>{row.sex}</td>
                <td>
                  <>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => handleRecover(row.id)}
                    >
                      Recover
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </>
                </td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  )
}

export default TrashPatientList
