import React, { useState } from "react";
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";
import CustomTable from "components/Table/Table";
import { useHistory } from "react-router";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPatients } from "store/actions/patient";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import Select from "react-select";
import { getTownships } from "store/actions/township";
import CustomPagination from "components/Pagination/Pagination";
import { paginationLimit } from "variables/limits";
import { patientMiddleware, EXPORT, CREATE } from "utils/middleware";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";

const TOPatientList = () => {
  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  const { patients, male, female, total } = useSelector(
    (state) => state.patient
  );
  const township = useSelector((state) => state.township);
  const loading = useSelector((state) => state.patient.loading);
  const user = useSelector((state) => state.auth);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.TO_status = "Yes";
    if (!("page" in query)) {
      query.page = 1;
    }
    dispatch(getPatients(query));
  }, [dispatch, router.location.search]);

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    if (selectedTownship) {
      query.current_township_id = selectedTownship.value;
    } else {
      delete query["current_township_id"];
    }

    if (name !== "") {
      query.name = name;
    } else {
      delete query["name"];
    }

    if (code !== "") {
      query.SP_code = code;
    } else {
      delete query["SP_code"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      const response = await call(
        "get",
        `patients-export?${new URLSearchParams(query).toString()}`
      );
      const result = response.data.map((data, index) => ({
        UniqueKey: data.id,
        No: index + 1,
        Township: data.township?.name,
        Year: data.year,
        SP_Code: data.SP_code,
        RR_Code: data.RR_code,
        DRTB_Code: data.DRTB_code,
        UniqueID: data.unique_id,
        Name: data.name,
        Age: data.age,
        Sex: data.sex,
        Treatment_Start_Date: data.treatment_start_date,
        TO_Status: data.TO_status,
        TO_Year: data.TO_year,
        TO_RR_Code: data.TO_RR_code,
        TO_DRTB_code: data.TO_DRTB_code,
        TO_UniqueID: data.TO_unique_id,
        TO_Township: data.TO_township?.name,
      }));

      let fileName = "TO-Patients";
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">TO/Patients</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(user?.user?.role, CREATE) && (
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => router.push(`/admin/create-patient`)}
                  >
                    New
                  </Button>
                )}
                {patientMiddleware(user?.user?.role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Search name..."
                />
              </Col>
              <Col sm={3}>
                <input
                  className="form-control mt-3"
                  type="text"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  placeholder="Search SP Code..."
                />
              </Col>
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Male = {male}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Female = {female}
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#0C5393", color: "#fff" }}
                >
                  Total = {total}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">SP Code</th>
                <th scope="col">Action</th>
                <th scope="col">TSP</th>
                <th scope="col">RR Code</th>
                <th scope="col">DRTB Code</th>
                <th scope="col">Township DRTB Code</th>
                <th scope="col">Name</th>
                <th scope="col">Age</th>
                <th scope="col">Sex</th>
                <th scope="col">TO Township DRTB Code</th>
              </tr>
            }
            body={patients.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.SP_code}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(`/admin/patient-detail/${row.id}`)
                    }
                  >
                    Detail
                  </Button>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() =>
                      router.push(`/admin/patient-packages/${row.id}`)
                    }
                  >
                    Packages
                  </Button>
                </td>
                <td>{row.township?.abbreviation}</td>
                <td>{row.RR_code}</td>
                <td>{row.DRTB_code}</td>
                <td>{row.unique_id}</td>
                <td>{row.name}</td>
                <td>{row.age}</td>
                <td>{row.sex}</td>
                <td>{row.TO_unique_id}</td>
              </tr>
            ))}
          />
          {total > paginationLimit && (
            <CustomPagination pageCount={total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default TOPatientList;
