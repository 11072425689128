export const skipMonth = (smokingStatus, smokingInsideHome) => {
  if (smokingStatus === 'No' && smokingInsideHome === 'No') {
    return true
  } else if (smokingStatus === 'Yes' && smokingInsideHome === 'No') {
    return false
  } else if (smokingStatus === 'No' && smokingInsideHome === 'Yes') {
    return false
  } else {
    return false
  }
}

export const townshipAcronyms = {
  AMP: 'Amarapura',
  PTG: 'Patheingyi',
  PGT: 'Pyigyitagon',
  MGN: 'Myingyan',
}

export const handleVillageAndWard = (township) => {
  if (
    township === townshipAcronyms.AMP ||
    township === townshipAcronyms.PTG ||
    township === townshipAcronyms.PGT ||
    township === townshipAcronyms.MGN
  ) {
    return true
  } else {
    return false
  }
}

export const monthDictionary = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
}
