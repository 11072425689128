import { createAsyncThunk } from '@reduxjs/toolkit'
import { call } from 'services/api'
import { serverErrorMessage } from 'utils/messages'
import { NotificationManager } from 'react-notifications'
import { setAccessToken } from 'services/api'

export const getPackages = createAsyncThunk(
  'package/getPackages',
  async (id) => {
    try {
      const result = await call('get', `packages/${id}`)

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getAllPackages = createAsyncThunk(
  'package/getAllPackages',
  async () => {
    try {
      const result = await call('get', `all-package`)

      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const getPackage = createAsyncThunk('package/getPackage', async (id) => {
  try {
    const response = await call('get', `package/${id}`)
    const result = response.data

    return result
  } catch (error) {
    const { status, data } = error.response

    if (status === 401) {
      setAccessToken(null)
      NotificationManager.error(data.data.message)
    } else {
      NotificationManager.error(serverErrorMessage)
    }
    return error.message
  }
})

export const createPackage = createAsyncThunk(
  'package/createPackage',
  async (data, thunkAPI) => {
    try {
      const response = await call('post', 'packages', data)
      const result = response.data

      NotificationManager.success('Package has been created successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response

      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const updatePackage = createAsyncThunk(
  'package/updatePackage',
  async (data, thunkAPI) => {
    try {
      const response = await call(
        'post',
        `packages/${data.id}?_method=PUT`,
        data,
      )
      const result = response.data

      NotificationManager.success('Package has been updated successfully!')
      return result
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const recoverPackage = createAsyncThunk(
  'package/recoverPackage',
  async (id) => {
    try {
      await call('get', `packages-recover/${id}`)

      NotificationManager.success('Package has been recovered successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const temDeletePackage = createAsyncThunk(
  'package/temDeletePackage',
  async (id) => {
    try {
      await call('get', `packages-delete/${id}`)

      NotificationManager.success('Package has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)

export const deletePackage = createAsyncThunk(
  'package/deletePackage',
  async (id) => {
    try {
      await call('delete', `packages/${id}`)

      NotificationManager.success('Package has been deleted successfully!')
      return id
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      return error.message
    }
  },
)
